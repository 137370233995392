import {View} from 'backbone.marionette';
import Backbone from 'backbone';
import i18next from 'i18next';
import imageTemplate from '../templates/image.hbs';

class TypeSelector extends View.extend({
  template: _.noop,
  events: {
    'change #brand-select': 'onBrandChange',
    'change #type-select': 'onTypeChange',
    'change #category-select': 'onCategoryChange',
    'click @ui.button': 'onButtonClick',
  },
  regions: {
    image: '#selected-type-image'
  },
  ui: {
    button: '#button-select'
  }
}) {
  initialize(options) {
    this.initialSelection = this.$el.data('initial-selection');
    this.redirect = this.$el.data('redirect');
    this.brand = this.initialSelection.brand;
    this.type = this.initialSelection.type;
    this.category = this.initialSelection.category;
    this.channel = Backbone.Radio.channel('typeSelection');

    //Collections
    this.carTypes = options.carTypes;
    this.categories = options.categories;

    this.showChildView('image', new View({template: imageTemplate}));

    this.setCarTypes();
    this.setCategories();

    this.bindUIElements();
  }
  onBrandChange(event) {
    this.brand = $(event.currentTarget).val();
    this.type = undefined;
    this.category = undefined;
    this.setCarTypes();
    this.setCategories();
    this.setImage(undefined);
    this.toggleButtonState(false);
  }
  setCarTypes() {
    this.carTypes.reset();
    if (this.brand) {
      this.carTypes.fetch({
        data: {brand: this.brand, language: i18next.language},
        localCache: true,
        success: function() {
          this.carTypes.add({term: 'selecteer_een_type', carTypeId: ''}, {at: 0});
          if (this.type && this.carTypes.has(this.type)) {
            const carType = this.carTypes.get(this.type);
            carType.set('selected', true);
            this.setImage(this.type);
            this.toggleButtonState(true);
          }
        }.bind(this)
      });
    }
  }
  onTypeChange(event) {
    const type = $(event.currentTarget).val();
    this.type = parseInt(type);
    this.category = undefined;
    this.setCategories();
    this.setImage(this.type);
    this.toggleButtonState(true);
  }
  setCategories() {
    this.categories.reset();
    if (this.type  && this.carTypes.has(this.type)) {
      this.categories.fetch({
        data: {type: this.type, language: i18next.language},
        localCache: true,
        success: function() {
          this.categories.shift();
          this.categories.add({term: 'selecteer_een_categorie', articleCategoryId: ''}, {at: 0});
          if (this.category && this.categories.has(this.category)) {
            const categoryModel = this.categories.get(this.category);
            categoryModel.set('selected', true);
            this.toggleButtonState(true);
          }
        }.bind(this)
      });
    }
  }
  onCategoryChange(event) {
    let category = $(event.currentTarget).val();
    category = parseInt(category);
    if (isNaN(category)) {
      category = null;
    }
    this.category = category;
    this.getUI('button').trigger('focus');
    this.onButtonClick();

  }
  setImage(type) {
    const imageView = this.getChildView('image');
    imageView.model = type ? this.carTypes.get(type) : undefined;
    imageView.render();
  }
  toggleButtonState(enabled) {
    const button = this.getUI('button');
    if (enabled) {
      button.removeClass('disabled');
      button.removeAttr('disabled');
    } else {
      button.addClass('disabled');
      button.attr('disabled', true);
    }
  }
  saveSelection() {
    if (this.type) {
      const data = {
        brand: this.brand,
        type: this.type,
        category: this.category
      };
      this.channel.request('setModel', data);
    }
  }
  onButtonClick() {
    let url = null;
    if (this.category) {
      const categoryModel = this.categories.get(this.category);
      url = categoryModel.get('url');
    } else if (this.type) {
      const carType = this.carTypes.get(this.type);
      url = carType.get('url');
    }
    if (this.redirect) {
      window.location = url;
    } else {
      this.saveSelection();
      this.resetCollapsables();
      Backbone.history.navigate(url, true);
    }
  }
  resetCollapsables() {
    $('#type-selector-collapse').collapse('hide');
    $('#type-selected-collapse').collapse('show');
  }
}

export default TypeSelector;
