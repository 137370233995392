import {Behavior} from 'backbone.marionette';

export default class AddressCheck extends Behavior.extend({
  ui: {
    postalCode: '.address-check-postal-code',
    houseNumber: '.address-check-house-number'
  },
  events: {
    'change @ui.postalCode': 'handleChange',
    'change @ui.houseNumber': 'handleChange'
  },
  postalCodeRegex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
  numericRegex: /^\d+$/
}) {
  initialize() {
    this.csrf = $('body').data('ajax-csrf');
  }
  handleChange(event) {
    const id = $(event.target).attr('id');
    let idPrefix;
    if (id.endsWith('PostalCode') || id.endsWith('HouseNumber')) {
      this.doCheck(id.slice(0, id.endsWith('PostalCode') ? -10 : -11));
    }
  }
  doCheck(idPrefix) {
    const postalCode = $(`#${idPrefix}PostalCode`).val().trim();
    const houseNumber = $(`#${idPrefix}HouseNumber`).val().trim();
    if (!this.postalCodeRegex.test(postalCode) || !this.numericRegex.test(houseNumber) || !parseInt(houseNumber) > 0) {
      return;
    }
    $.post({
      data: JSON.stringify({ postalCode: postalCode, houseNumber: houseNumber, csrf: this.csrf }),
      url: '/api/address',
      dataType: 'json',
      contentType: 'application/json',
      success: function(data) {
        this.handleAddressSuccess(data, idPrefix);
      }.bind(this)
    });
  }
  handleAddressSuccess(data, idPrefix) {
    if (data.street !== undefined) {
      $(`#${idPrefix}Street`).val(data.street)
    }
    if (data.city !== undefined) {
      $(`#${idPrefix}City`).val(data.city)
    }
  }
}