import NodeModule from '../../services/NodeModule';
import TypeSelectorResponsive from './views/TypeSelectorResponsive';

class TypeSelectorResponsiveNode extends NodeModule {
  start(options) {
    console.log('Starting Type Selector Responsive');
    const typeSelectorResponsive = new TypeSelectorResponsive({el: options.el});
  }
}

export default TypeSelectorResponsiveNode;
