import {View} from 'backbone.marionette';

class TypeSelectorResponsive extends View.extend({
  template: _.noop,
  ui: {
    collapseLink: '#collapse-link',
    typeSelectorCollapse: '#type-selector-collapse'
  },
  events: {
    'hidden.bs.collapse @ui.typeSelectorCollapse': 'onHideCollapse',
    'shown.bs.collapse @ui.typeSelectorCollapse': 'onShowCollapse'
  }
}) {
  initialize(options) {
    this.bindUIElements();
  }
  onShowCollapse() {
    const chevron = this.getUI('collapseLink').find('span.fa');
    chevron.removeClass('fa-chevron-down').addClass('fa-chevron-up');
  }
  onHideCollapse() {
    const chevron = this.getUI('collapseLink').find('span.fa');
    chevron.removeClass('fa-chevron-up').addClass('fa-chevron-down');
  }
}

export default TypeSelectorResponsive;
