import {View} from 'backbone.marionette';
import Backbone from 'backbone';
import template from '../templates/categoryRadio.hbs';

class CategoryListElement extends View.extend({
  template: template,
  tagName: 'li',
  ui: {
    radio: '.select-category',
    label: '.label-category'
  },
  events: {
    'click @ui.radio': 'onClick'
  }
}) {
  initialize(options) {
    this.channel = Backbone.Radio.channel('typeSelection');
  }
  templateContext() {
    const selectedCategory = this.getOption('selectedCategory');
    return {
      selectedCategory: selectedCategory ? selectedCategory : 'all',
    };
  }
  onClick(event) {
    const radioInput = this.getUI('radio');
    const url = radioInput.data('url');
    let value = radioInput.val();
    if (value === 'all') {
      value = undefined;
    } else {
      value = parseInt(value);
    }
    Backbone.history.navigate(url, true);
    this.triggerMethod('click:element', this);
    this.channel.request('set:category', value);
  }
}

export default CategoryListElement;