import {View} from 'backbone.marionette';

export default class FormValidator extends View.extend({
  formElements: {},
  events: {
    'submit': 'handleSubmit'
  },
  childViewEventPrefix: 'childview'
}) {
  initialize(options) {
  }
  registerFormElement(region, formElement) {
    this.addRegion(region, formElement.options.el);
    this.showChildView(region, formElement);
  }
  setFormElementsFromConfig(config) {
    for (const [region, formElementConfig] of Object.entries(config)) {
      this.setFormElementFromConfig(region, formElementConfig);
    }
  }
  setFormElementFromConfig(region, formElementConfig) {
    if (formElementConfig.options.el === undefined) {
      throw 'No element defined for form element';
    }
    if (formElementConfig.type === undefined) {
      throw 'No type defined for form element';
    }
    if ($(formElementConfig.options.el).length) {
      formElementConfig.options['region'] = region;
      formElementConfig.options['parent'] = this;
      this.registerFormElement(region, new formElementConfig.type(formElementConfig.options));
    }
  }
  handleSubmit(event) {
    if (!this.isValid()) {
      event.preventDefault();
      this.triggerMethod('submission:error', this);
    }
  }
  getValues() {
    let values = {};
    Object.keys(this.getRegions()).forEach(function (regionName) {
      values[regionName] = this.getChildView(regionName).getValue();
    }.bind(this));
    return values;
  }
  isValid() {
    let valid = true;
    const values = this.getValues();
    Object.keys(this.getRegions()).forEach(function (regionName) {
      if (!this.getChildView(regionName).isValid(values, true)) {
        valid = false;
      }
    }.bind(this));
    return valid;
  }
  isValidFormElement(formElementName) {
    return this.getChildView(formElementName).isValidWithoutMessages();
  }
  onChildviewFocusFormelement(view) {
    this.triggerMethod('focus:formelement', view);
  }
};