import NodeModule from '../../services/NodeModule';
import CategorySelector from './views/CategorySelector';
import Categories from '../typeselector/collections/Categories';

class CategorySelectorNode extends NodeModule {
  start(options) {
    console.log('Starting Category Selector');
    const categorySelector = new CategorySelector({el: options.el, collection: new Categories()});
  }
}

export default CategorySelectorNode;
