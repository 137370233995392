import NodeModule from '../../services/NodeModule';
import TypeSelected from './views/TypeSelected';

class TypeSelectorNode extends NodeModule {
  start(options) {
    console.log('Starting Type Selected');
    const typeSelected = new TypeSelected({el: options.el});
  }
}

export default TypeSelectorNode;
