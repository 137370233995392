import {CollectionView} from 'backbone.marionette';
import BasketItem from './BasketItem';
import EmptyBasket from './EmptyBasket';
import Stock from '../../../behaviors/Stock';

class BasketItems extends CollectionView.extend({
  childView: BasketItem,
  childViewEvents: {
    'show:overlay': 'showOverlay'
  },
  emptyView: EmptyBasket,
  behaviors: [Stock]
}) {
  childViewOptions(model) {
    let previousModelName = null;
    const index = this.collection.indexOf(model);
    if (index > 0) {
      previousModelName = this.collection.at(index - 1).get('carType');
    }
    return {
      previousModelName: previousModelName,
      index: index
    };
  }
  showOverlay(view) {
    this.triggerMethod('show:overlay', view);
  }
}

export default BasketItems;
