import BaseElement from './BaseElement';

export default class SelectElement extends BaseElement.extend({
  ui: {
    element: 'select'
  }
}) {
  getValue() {
    return this.getUI('element').children("option:selected").val();
  }
  setValue(value) {}
}