import {Model} from 'backbone';
import Items from '../collections/Items';
import Totals from './Totals';

class Basket extends Model.extend({
  url: '/api/basketitems?parts=true'
}) {
  initialize(options) {
    this.items = new Items();
    this.totals = new Totals();
  }
  parse(data) {
    this.items.set(data.items);
    delete data.items;
    this.totals.set(data);
  }
}

export default Basket;
