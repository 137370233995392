var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\" role=\"document\">\n    <div class=\"modal-content\">\n        <div class=\"modal-body\">\n            <div class=\"container-fluid\">\n                <div class=\"row\">\n                    <div class=\"col-12 d-flex justify-content-end\">\n                        <span class=\"fa fa-2x fa-shopping-cart\"></span>\n                        <sup class=\"badge-sup\"><span class=\"badge badge-pill badge-primary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":8,"column":92},"end":{"line":8,"column":101}}}) : helper)))
    + "</span></sup>\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-12\">\n                        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"basketUrl") || (depth0 != null ? lookupProperty(depth0,"basketUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"basketUrl","hash":{},"data":data,"loc":{"start":{"line":13,"column":33},"end":{"line":13,"column":46}}}) : helper)))
    + "\" class=\"btn btn-block btn-primary mt-2\">\n                            "
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"naar_winkelwagen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":52}}}))
    + "\n                            <i class=\"button-icon fa fa-lg fa-shopping-cart\"></i>\n                        </a>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});