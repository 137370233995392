import BaseElement from './BaseElement';

export default class RadioElement extends BaseElement.extend({
  ui: {
    element: 'input:radio'
  }
}) {
  getValue() {
    const element = this.$el.find('input:radio:checked');
    if (element.length) {
      return element.val();
    }
    return null;
  }
  setValue(value) {}
  attachHelpBlock() {
    this.$el.find('.card-group-toggle').append(`<div class="w-100 d-block text-center radio-help-block">${this.getHelpBlock()}</div>`);
  }
  detachHelpBlock() {
    this.$el.find('.radio-help-block').remove();
  }
}