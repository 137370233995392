import { View } from 'backbone.marionette';
import ReviewsCollection from '../collections/Reviews';
import template from '../templates/reviews.hbs';

export default class ReviewList extends View.extend({
  template,
  templateContext() {
    return this.state;
  }
}) {
  initialize(options) {
    this.state = {
      isPending: true,
    };
    this.productIds = this.$el.data('product-ids');
    this.collection = new ReviewsCollection();

    this.getReviews();
    this.render();
  }

  getReviews() {
    const { productIds } = this;

    this.onQueryReviewsStarted();
    this.collection.queryItems({ productIds })
      .then((reviews) => this.onQueryReviewsFinished(reviews))
      .catch(() => this.onQueryReviewsFailed())
    ;
  }

  onQueryReviewsStarted() {
    this.state.isPending = true;
    this.render();
  }

  onQueryReviewsFailed() {
    this.state.isPending = false;
    this.render();
  }

  onQueryReviewsFinished(reviews) {
    this.state = reviews;
    this.state.isPending = false;
    this.render();
  }
};
