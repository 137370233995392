export default class Case {
  filter(value) {
    switch (this.caseType) {
      case 'upper':
        return value.toUpperCase();
      case 'lower':
        return value.toLowerCase;
      default:
        return value;
    }
  }
  setOptions(options) {
    this.caseType = options.caseType;
  }
}