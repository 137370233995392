import {View} from 'backbone.marionette';
import {Radio} from 'backbone';

class PositionSelector extends View.extend({
  ui: {
    frontButton: '.car-side.car-side-front',
    rearButton: '.car-side.car-side-rear'
  },
  events: {
    'click @ui.frontButton': 'onClickFrontButton',
    'click @ui.rearButton': 'onClickRearButton'
  }
}) {
  initialize(options) {
    this.initialSelection = this.$el.data('initial-selection');
    this.selectedFront = this.initialSelection.positionFrontRear === 'f';
    this.selectedRear = this.initialSelection.positionFrontRear === 'r';
    this.setAllButtonsState();
    this.channel = Radio.channel('typeSelection');
    this.listenTo(this.channel, 'change', this.onSelectionChange);
  }
  onClickRearButton() {
    this.selectedRear = !this.selectedRear;
    this.selectedFront = this.selectedRear ? false : this.selectedFront;
    this.onClickButton();
  }
  onClickFrontButton() {
    this.selectedFront = !this.selectedFront;
    this.selectedRear = this.selectedFront ? false : this.selectedRear;
    this.onClickButton();
  }
  onClickButton() {
    this.setPosition();
    this.setAllButtonsState();
  }
  setPosition() {
    let value = null;
    if (this.selectedFront) {
      value = 'f';
    } else if (this.selectedRear) {
      value = 'r';
    }
    this.channel.request('set:positionFrontRear', value);
  }
  setAllButtonsState() {
    this.setButtonState(this.getUI('frontButton'), this.selectedFront);
    this.setButtonState(this.getUI('rearButton'), this.selectedRear);
  }
  setButtonState(element, selected) {
    if (selected) {
      element.addClass('active');
    } else {
      element.removeClass('active');
    }
  }
  onSelectionChange(model) {
    let position = model.get('positionFrontRear');
    this.selectedFront = position === 'f';
    this.selectedRear = position === 'r';
    this.setAllButtonsState();
  }
}

export default PositionSelector;
