import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import template from '../templates/basketItem.hbs';
import RelatedPartsModal from './RelatedPartsModal';
import Popover from '../../../utils/Popover';
import i18next from 'i18next';
import ColorCodeLocation from '../../../behaviors/ColorCodeLocation';
import ColorCodeAutocomplete from '../../../behaviors/ColorCodeAutocomplete';

class BasketItem extends View.extend({
  behaviors: [ColorCodeLocation, ColorCodeAutocomplete],
  template: template,
  ui: {
    removeItem: '.remove-item',
    increaseItemCount: '.increase-item',
    decreaseItemCount: '.decrease-item',
    itemCount: '.item-count',
    colorCode: '.color-code',
    addColorCode: '.add-color-code',
    removeColorCode: '.remove-color-code',
    relatedParts: '.related-parts'
  },
  events: {
    'click @ui.removeItem': 'removeItem',
    'click @ui.increaseItemCount': 'increase',
    'click @ui.decreaseItemCount': 'decrease',
    'click @ui.addColorCode': 'addColorCode',
    'click @ui.removeColorCode': 'removeColorCode',
    'click @ui.relatedParts': 'showRelatedParts'
  }
}) {
  initialize(options) {
    this.basketChannel = Radio.channel('basket');
    this.timer = null;
  }
  templateContext() {
    return {
      previousModelName: this.getOption('previousModelName'),
      index: this.getOption('index')
    };
  }
  removeItem() {
    this.triggerMethod('show:overlay', this);
    this.basketChannel.request('removeItems', [this.model.get('basketId')]);
  }
  increase() {
    this.changeItemCount(true);
  }
  decrease() {
    this.changeItemCount(false);
  }
  changeItemCount(increase) {
    const inputField = this.getUI('itemCount');
    const value = parseInt(inputField.val());
    const newValue = increase ? value + 1 : value - 1;
    inputField.val(newValue);
    if (newValue === 1) {
      this.getUI('decreaseItemCount').attr('disabled', true);
    } else {
      this.getUI('decreaseItemCount').removeAttr('disabled');
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(function() {
      const quantity = parseInt(this.getUI('itemCount').val());
      const item = {
        quantity: quantity,
      };
      this.triggerMethod('show:overlay', this);
      this.basketChannel.request('updateItem', this.model.get('basketId'), item);
    }.bind(this), 1000);
  }
  addColorCode() {
    const colorCode = this.getUI('colorCode').val();
    if (colorCode) {
      this.triggerMethod('show:overlay', this);
      this.basketChannel.request('updateItem', this.model.get('basketId'), {colorCode: colorCode});
    } else {
      Popover.popover(this.getUI('colorCode'), i18next.t('vul_een_kleurcode_in'), 'warning');
    }
  }
  removeColorCode() {
    this.triggerMethod('show:overlay', this);
    this.basketChannel.request('updateItem', this.model.get('basketId'), {colorCode: null});
  }
  showRelatedParts() {
    Radio.channel('modal').request('showModal', new RelatedPartsModal({
      data: this.model.get('carPart').relatedGeeversCarParts,
      carTypeId: this.model.get('carTypeId')}));
  }
}

export default BasketItem;
