import {Behavior} from 'backbone.marionette';

class ColorCodeAutocomplete extends Behavior.extend({
  ui: {
    colorCode: '.color-code'
  }
}) {
  onRender() {
    this.getUI('colorCode').each(function(index, element) {
      $(element).autocomplete({
        serviceUrl: '/api/colorcodes',
        minChars: 0,
        noCache: true,
        zIndex: 1000
      });
    });
  }
}

export default ColorCodeAutocomplete;
