import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import template from '../templates/carPart.hbs';
import i18next from 'i18next';
import Popover from '../../../utils/Popover';
import Money from 'js-money';
import MoneyFormatter from '../../../utils/MoneyFormatter';
import ButtonHelper from '../../../utils/ButtonHelper';

class CarPart extends View.extend({
  template: template,
  className: 'product col-12 col-sm-6 col-md-4 col-xl-3 px-1 pb-2 d-flex',
  events: {
    'click @ui.spray': 'onClickSpray',
    'click @ui.order': 'onClickOrder',
    'click @ui.left': 'onClickLeft',
    'click @ui.right': 'onClickRight'
  },
  ui: {
    spray: '.spray-carpart',
    order: '.order-carpart',
    left: '.order-left',
    right: '.order-right',
    checkLeftRight: '.checkleftright'
  }
}) {
  initialize() {
    this.rightSelected = false;
    this.leftSelected = false;
    this.addingItems = false;
  }
  onClickSpray() {
    if (this.model.get('hasSprayCode')) {
      const url = this.model.get('url') + '?spray=true';
      let sideParameter = '';
      sideParameter += this.rightSelected ? 'r' : '';
      sideParameter += this.leftSelected ? 'l' : '';
      if (sideParameter.length > 0) {
        sideParameter = '&sides=' + sideParameter;
      }
      window.location = url + sideParameter;
    }
  }
  onClickOrder() {
    if (this.addingItems) {
      return;
    }
    let items = [];
    const sides = this.model.get('sides');
    if (this.model.get('isSidePart')) {
      if (!this.model.get('hasCouple')) {
        items.push(this.getItem(
          this.model.get('carPartId')
        ));
      } else if (this.leftSelected || this.rightSelected) {
        if (this.leftSelected) {
          items.push(this.getItem(
            sides.l
          ));
        }
        if (this.rightSelected) {
          items.push(this.getItem(
            sides.r
          ));
        }
      } else {
        const div = this.getUI('checkLeftRight');
        Popover.popover(div, i18next.t('selecteer_een_zijde'), 'warning');
      }
    } else {
      items.push(this.getItem(
        this.model.get('carPartId')
      ));
    }
    if (items.length > 0) {
      this.addingItems = true;
      this.getUI('order').addClass('disabled');
      this.getUI('order').children('.button-icon')
        .addClass("fa-circle-o-notch fa-spin")
        .removeClass("fa-shopping-cart");
      const channel = Radio.channel('basket');
      channel.request('addItems', items, {
        success: (data) => {
          this.reset();
          if (data.added > 0) {
            ButtonHelper.blinkSuccess(this.getUI('order'));
            Radio.channel('modal').request('showBasketUpdateModal', data.count, data.basketUrl);
          } else {
            ButtonHelper.blinkDanger(this.getUI('order'));
          }
        },
        error: (data) => {
          this.reset();
          ButtonHelper.blinkDanger(this.getUI('order'));
        }
      });
    }
  }
  onClickLeft() {
    if (this.addingItems) {
      return;
    }
    if ((this.model.get('sides')).l) {
      this.leftSelected = this.leftSelected === false;
      if (this.leftSelected) {
        this.getUI('left').addClass('active');
      } else {
        this.getUI('left').removeClass('active');
      }
    }
    this.setAmount();
  }
  onClickRight() {
    if (this.addingItems) {
      return;
    }
    if ((this.model.get('sides')).r) {
      this.rightSelected = this.rightSelected === false;
      if (this.rightSelected) {
        this.getUI('right').addClass('active');
      } else {
        this.getUI('right').removeClass('active');
      }
    }
    this.setAmount();
  }
  setAmount() {
    const double = this.leftSelected && this.rightSelected;
    const money = Money.fromInteger(this.model.get('price'));
    this.$el.find('.car-part-price').first().html(
      MoneyFormatter.formatMoney(double ? money.multiply(2) : money)
    );
  }
  getItem(carPartId) {
    return {
      articleId: carPartId,
      quantity: 1,
      carTypeId: this.model.get('carTypeId'),
      articleType: 'pp'
    };
  }
  showItemsAddedPopOver(count) {
    Popover.popover(this.getUI('order'), i18next.t('artikel_toegevoegd', {count: count}));
  }
  reset() {
    this.rightSelected = false;
    this.leftSelected = false;
    this.addingItems = false;
    this.getUI('right').removeClass('active');
    this.getUI('left').removeClass('active');
    this.getUI('order')
      .removeClass('disabled')
      .children('.button-icon')
      .removeClass("fa-circle-o-notch fa-spin")
      .addClass("fa-shopping-cart");
  }
}

export default CarPart;
