import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import TypeSelectorNode from './modules/typeselector/TypeSelectorNode';
import TypeSelectionHandler from './services/typeselection/TypeSelectionHandler';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import Handlebars from 'handlebars/runtime';
import '../libs/jalc/jalc.min';
import 'devbridge-autocomplete';
import 'bootstrap';
import PositionSelectorNode from './modules/positionselector/PositionSelectorNode';
import CategorySelectorNode from './modules/categoryselector/CategorySelectorNode';
import PartsOverviewNode from './modules/partsoverview/PartsOverviewNode';
import * as money from './handlebars/helpers/money';
import checkCondition from './handlebars/helpers/ifCond';
import BasketService from './services/basket/BasketService';
import ModalNode from './modules/modal/ModalNode';
import BasketBadgeNode from './modules/basketbadge/BasketBadgeNode';
import TypeSelectorResponsiveNode from './modules/typeselectorresponsive/TypeSelectorResponsiveNode';
import BasketNode from './modules/basket/BasketNode';
import PartDetailNode from './modules/partdetail/PartDetailNode';
import ReviewListNode from './modules/partdetail/ReviewListNode';
import TypeSelectedNode from './modules/typeselected/TypeSelectedNode';
import ImageCarouselNode from './modules/imagecarousel/ImageCarouselNode';
import BreadcrumbNode from './modules/breadcrumb/BreadcrumbNode';
import CheckoutNode from './modules/checkout/CheckoutNode';
import UserRegistrationNode from './modules/user/UserRegistrationNode';
import UserDataNode from './modules/user/UserDataNode';
import UserLoginNode from './modules/user/UserLoginNode';
import UserDataEmailNode from './modules/user/UserDataEmailNode';
import UserDataPasswordNode from './modules/user/UserDataPasswordNode';
import UserLostPasswordNode from './modules/user/UserLostPasswordNode';
import UserUpdateLostPasswordNode from './modules/user/UserUpdateLostPasswordNode';

class PandPApplication extends Marionette.Application.extend({}) {
  onStart(application, options) {
    this.registerHandlebarsHelpers();

    const typeSelectionHandler = new TypeSelectionHandler();
    const basketService = new BasketService();
    const modalRegion = new ModalNode({el: '#modal-region'});
    const basketBadge = new BasketBadgeNode({el: 'li.menu-basket'});
    const typeSelector = new TypeSelectorNode({el: '#type-selector'});
    const typeSelected = new TypeSelectedNode({el: '.type-selected'});
    const typeSelectorResponsive = new TypeSelectorResponsiveNode({el: '#type-selector-responsive'});
    const breadcrumb = new BreadcrumbNode({el: '.breadcrumb-nav'});
    const positionSelector = new PositionSelectorNode({el: '#position-selector'});
    const categorySelector = new CategorySelectorNode({el: '#category-selector'});
    const partsOverview = new PartsOverviewNode({el: '#parts-overview'});
    const partDetail = new PartDetailNode({el: '#part-detail'});
    const reviewList = new ReviewListNode({ el: '.review-container' });
    const basket = new BasketNode({el: '#basket'});
    const checkout = new CheckoutNode({el: '#checkout'});
    const userRegistration = new UserRegistrationNode({el: '#user-registration'});
    const userData = new UserDataNode({el: '#user-data'});
    const userEmail = new UserDataEmailNode({el: '#user-data-email'});
    const userPassword = new UserDataPasswordNode({el: '#user-data-password'});
    const userLogin = new UserLoginNode({el: '#user-login'});
    const userLostPassword = new UserLostPasswordNode({el: '#user-lost-password'});
    const userUpdateLostPassword = new UserUpdateLostPasswordNode({el: '#user-update-lost-password'});
    const imageCarousel = new ImageCarouselNode({el: '.image-carousel'});
    Backbone.history.start({ pushState: true });
  }
  registerHandlebarsHelpers() {
    Handlebars.registerHelper('money', money.money);
    Handlebars.registerHelper('ifCond', function(v1, operator, v2, handleBarsOptions) {
      return checkCondition(v1, operator, v2) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
    Handlebars.registerHelper('moneyEquals', function(moneyObject1, moneyObject2, handleBarsOptions) {
      return money.moneyEquals(moneyObject1, moneyObject2) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
    Handlebars.registerHelper('moneyGreaterThen', function(moneyObject1, moneyObject2, handleBarsOptions) {
      return money.moneyGreaterThen(moneyObject1, moneyObject2) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
    Handlebars.registerHelper('moneyGreaterEqualsThen', function(moneyObject1, moneyObject2, handleBarsOptions) {
      return money.moneyGreaterEqualsThen(moneyObject1, moneyObject2) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
    Handlebars.registerHelper('moneyEqualsZero', function(moneyObject, handleBarsOptions) {
      return money.moneyEqualsZero(moneyObject) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
    Handlebars.registerHelper('moneyGreaterThenZero', function(moneyObject, handleBarsOptions) {
      return money.moneyGreaterThenZero(moneyObject) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
    Handlebars.registerHelper('moneyGreaterEqualsThenZero', function(moneyObject, handleBarsOptions) {
      return money.moneyGreaterEqualsThenZero(moneyObject) ?
        handleBarsOptions.fn(this) : handleBarsOptions.inverse(this);
    });
  }
}

const loadInitialData = function() {
  return new Promise(
    (resolve, reject) => {
      i18next
        .use(Backend)
        .init({
          saveMissing: true,
          returnNull: false,
          nsSeparator: false,
          fallbackLng: false,
          lng: document.documentElement.lang,
          backend: {
            backends: [
              LocalStorageBackend,
              HttpApi
            ],
            backendOptions: [{
              expirationTime: 24 * 60 * 60 * 1000
            }, {
              loadPath: '/api/translations/{{lng}}',
              addPath: '/api/translations/{{lng}}'
            }]
          }
        }, function(err, t) {
          if (err) {
            reject(err);
            return;
          }
          Handlebars.registerHelper('t', function(key) {
            return new Handlebars.SafeString(t(key));
          });
          Handlebars.registerHelper('tplural', function(key, count) {
            return new Handlebars.SafeString(t(key, {count: count}));
          });
          resolve();
        });
    }
  );
};

const app = new PandPApplication();

loadInitialData().then(() => app.start());