import Popover from './Popover';

class ButtonHelper {
  static blinkInfo(button) {
    this.blink(button, 'info', 'info');
  }
  static blinkSuccess(button) {
    this.blink(button, 'success', 'check');
  }
  static blinkWarning(button) {
    this.blink(button, 'warning', 'exclamation');
  }
  static blinkDanger(button) {
    this.blink(button, 'danger', 'times');
  }
  static blink(button, color, icon) {
    const buttonClasses = ['btn-primary', 'btn-secondary', 'btn-light', 'btn-dark', 'btn-outline-primary', 'btn-outline-secondary', 'btn-outline-light', 'btn-outline-dark'];
    const iconClasses = ['fa-shopping-cart'];
    const iconElement = button.children('.button-icon').first();
    var colorClass = '';
    var colorClassBlink = '';
    var iconClass = '';
    var iconClassBlink = '';
    $(buttonClasses).each(function (index, buttonClass) {
      if (button.hasClass(buttonClass)) {
        colorClass = buttonClass;
        colorClassBlink = colorClass.substring(0, colorClass.lastIndexOf('-') + 1) + color;
        button.removeClass(colorClass);
        button.addClass(colorClassBlink);
      }
    });
    if (iconElement.length > 0) {
      $(iconClasses).each(function (index, iconClassElement) {
        if (iconElement.hasClass(iconClassElement)) {
          iconClass = iconClassElement;
          iconClassBlink = iconClass.substring(0, iconClass.indexOf('-') + 1) + icon;
          iconElement.removeClass(iconClass);
          iconElement.addClass(iconClassBlink);
        }
      });
    }
    setTimeout(function() {
      button.removeClass(colorClassBlink);
      button.addClass(colorClass);
      if (iconElement.length > 0) {
        iconElement.removeClass(iconClassBlink);
        iconElement.addClass(iconClass);
      }
    }, 2000);
  }
}

export default ButtonHelper;