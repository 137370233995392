import EmailValidator from 'email-validator';
import BaseValidator from './BaseValidator';

export default class Email extends BaseValidator {
  constructor() {
    super({emailNotValid: 'Not a valid email address'});
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    const valid = EmailValidator.validate(value);
    if (!valid) {
      this.addMessage(this.validationMessages.emailNotValid);
    }
    return valid;
  }
}