import BaseValidator from './BaseValidator';

export default class VatCode extends BaseValidator {
  constructor() {
    super({
      vatCodeNotValidBE: 'Not a valid Belgian vat code',
      vatCodeNotValidDE: 'Not a valid German vat code',
      vatCodeNotValidNL: 'Not a valid Dutch vat code',
      missingToken: 'No token was provided to match against'
    });
    this.regex = {
        BE: /^(BE)(\d{10}$)/,
        DE: /^(DE)([1-9]\d{8}$)/,
        NL: /^(NL)(\d{9}B\d{2}$)/
    };
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    if (!onSubmit) {
      return true;
    }
    if (values[this.token] === undefined) {
      this.addMessage(this.validationMessages.missingToken);
      return false;
    }
    const country = values[this.token];
    if (this.regex[country] === undefined) {
      return true;
    }
    const valid = this.regex[country].test(value);
    if (!valid) {
      this.addMessage(this.validationMessages['vatCodeNotValid' + country]);
    }
    return valid;
  }
  setOptions(options) {
    super.setOptions(options);
    if (options.token === undefined) {
      throw 'No token provided to compare against'
    }
    this.token = options.token;
  }
}