import NodeModule from '../../services/NodeModule';
import Basket from './views/Basket';

class BasketNode extends NodeModule {
  start(options) {
    console.log('Starting Basket');
    const basket = new Basket({el: options.el});
  }
}

export default BasketNode;
