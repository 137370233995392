import BaseElement from './BaseElement';

export default class CheckboxElement extends BaseElement.extend({
  ui: {
    element: 'input:checkbox'
  }
}) {
  getValue() {
    const element = this.getUI('element');
    if (element.prop('checked')) {
      return element.val();
    }
    const hidden = element.prev(':hidden');
    if (hidden.length === 1) {
      return hidden.val();
    }
    return null;
  }
  setValue(value) {}
}