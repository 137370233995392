import {Behavior} from 'backbone.marionette';

class Modal extends Behavior.extend({
  defaults: {
    modalClasses: '',
    modalOptions: null
  },
  ui: {
    close: '.close-modal'
  },
  events: {
    'hidden.bs.modal': 'triggerFinish',
  },
  triggers: {
    'click @ui.close': 'close:modal'
  }
}) {
  onRender() {
    this.view.$el.addClass('modal ' + this.getOption('modalClasses'));
  }
  onAttach() {
    this.view.$el.modal(this.getOption('modalOptions') || {});
    const delay = this.getOption('delay');
    if (delay) {
      setTimeout(() => {this.onCloseModal();}, delay);
    }
  }
  onCloseModal() {
    this.view.$el.modal('hide');
  }
  triggerFinish() {
    this.view.triggerMethod('destroy:modal');
  }
}

export default Modal;
