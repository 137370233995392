import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import CarType from '../../typeselector/models/CarType';

class TypeSelected extends View.extend({
  ui: {
    brandName: '.brand-name',
    typeName: '.type-name',
    typeDescription: '.type-description',
    image: '.type-image'
  }
}) {
  initialize(options) {
    this.typeSelectionChannel = Radio.channel('typeSelection');
    this.listenTo(this.typeSelectionChannel, 'change', this.onSelectionChange);
  }
  onSelectionChange(model) {
    const type = model.get('type');
    if (!type) {
      return;
    }
    this.model = new CarType({carTypeId: type});
    this.listenTo(this.model, 'sync', this.onModelSync);
    this.model.fetch();
  }
  onModelSync(model) {
    this.getUI('brandName').html(model.get('brandName'));
    this.getUI('typeName').html(model.get('name'));
    this.getUI('typeDescription').html(model.get('description'));
    if (model.get('description')) {
      this.getUI('typeDescription').closest('.row').removeClass('hide');
    } else {
      this.getUI('typeDescription').closest('.row').addClass('hide');
    }
    this.getUI('image').attr('src', model.get('image'));
  }
}

export default TypeSelected;
