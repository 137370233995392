class Popover {
  static popover(element, text, type) {
    let iconClass;
    let textClass;
    switch (type) {
      case 'warning':
        iconClass = 'fa-exclamation-circle';
        textClass = 'text-warning';
        break;
      default:
        iconClass = 'fa-info-circle';
        textClass = 'text-info';
        break;
    }
    element.popover({
        placement: 'top',
        trigger: 'manual',
        html: true,
        content: '<i class=\'fa fa-lg ' + iconClass + '\'></i>&nbsp;&nbsp;' + text,
        template: 	'<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body ' + textClass + '"></div></div>'
      });
    element.popover('show');
    setTimeout(() => {
      element
        .popover('dispose');
    }, 2000);
  }
}

export default Popover;
