import NodeModule from '../../services/NodeModule';
import PartsOverview from './views/PartsOverview';

class PartsOverviewNode extends NodeModule {
  start(options) {
    console.log('Starting Parts Overview');
    const partsOverview = new PartsOverview({ el: options.el });
  }
}

export default PartsOverviewNode;
