import Backbone from 'backbone';

class NodeModule {
  constructor(options) {
    this.cidPrefix = 'nm';
    let el = options.el;
    this.$el = el instanceof Backbone.$ ? el : Backbone.$(el);
    this.el = this.$el[0];
    this.cid = _.uniqueId(this.cidPrefix);
    this.initialize.apply(this, arguments);
  }
  initialize(options) {
    if (this.$el === undefined) {
      throw 'El property not set';
    }
    $(function() {
      if (this.$el.length) {
        this.$el.each($.proxy(function(index, el) {
          options.el = el
          this.start(options);
        }, this));
      }
    }.bind(this));
  }
  start(options) {}
}

export default NodeModule;
