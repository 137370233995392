import Marionette from 'backbone.marionette';
import TypeSelection from './models/TypeSelection';

class TypeSelectionHandler extends Marionette.Object.extend({
  channelName: 'typeSelection',
  radioRequests: {
    'set:brand': 'setBrand',
    'set:type': 'setType',
    'set:category': 'setCategory',
    'set:positionFrontRear': 'setPositionFrontRear',
    'setModel': 'setModel',
    'get:brand': 'getBrand',
    'get:type': 'getType',
    'get:category': 'getCategory',
    'get:positionFrontRear': 'getPositionFrontRear'
  }
}) {
  initialize(options) {
    console.log('Initializing Type Selection Handler');
    this.model = new TypeSelection();
    this.model.on('change', this.onModelChange, this);
    this.model.fetch({silent: true});
  }
  onModelChange(model) {
    const channel = this.getChannel();
    channel.trigger('change', model);
    _.each(model.changedAttributes(), function(value, key) {
      channel.trigger('change:' + key, value);
    });
  }
  setBrand(brand) {
    this.model.save({brand: brand, type: null, category: null, positionFrontRear: null});
  }
  setType(type) {
    this.model.save({type: type, category: null, positionFrontRear: null});
  }
  setCategory(category) {
    this.model.save({category: category, positionFrontRear: null});
  }
  setPositionFrontRear(positionFrontRear) {
    this.model.save({positionFrontRear: positionFrontRear});
  }
  setModel(model) {
    this.model.save(model);
  }
  getBrand() {
    return this.model.get('brand');
  }
  getType() {
    return this.model.get('type');
  }
  getCategory() {
    return this.model.get('category');
  }
  getPositionFrontRear() {
    return this.model.get('positionFrontRear');
  }
}

export default TypeSelectionHandler;
