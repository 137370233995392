import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import template from '../templates/basket.hbs';
import BasketModel from '../models/Basket';
import BasketItems from './BasketItems';
import BasketTotals from './BasketTotals';
import i18next from 'i18next';

class Basket extends View.extend({
  template: template,
  childViewEvents: {
    'show:overlay': 'showOverlay'
  },
  regions: {
    basketItems: '#basket-items',
    basketTotals: '#basket-totals'
  },
  ui: {
    overlay: '.basket-overlay'
  }
}) {
  initialize(options) {
    this.basketChannel = Radio.channel('basket');
    this.listenTo(this.basketChannel, 'item:added item:deleted item:deletedAll item:updated', this.onItemsChanged);
    this.basket = new BasketModel();
    this.basket.fetch({
      data: {language: i18next.language},
      success: function() {
        this.render();
      }.bind(this)
    });
  }
  onRender() {
    this.showChildView('basketItems', new BasketItems({collection: this.basket.items, el: '#basket-items'}));
    this.showChildView('basketTotals', new BasketTotals({model: this.basket.totals, el: '#basket-totals'}));
  }
  onItemsChanged() {
    if (this.basketChannel.request('getCount') === 0) {
      window.location = $('body').data('root');
    } else {
      this.basket.fetch({
        data: {language: i18next.language},
        success: function () {
          this.getUI('overlay').hide();
        }.bind(this)
      });
    }
  }
  showOverlay(view) {
    this.getUI('overlay').show();
  }
}

export default Basket;
