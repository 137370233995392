import {CollectionView, View} from 'backbone.marionette';
import i18next from 'i18next';

export default class HelpBlock extends CollectionView.extend({
  tagName: 'span',
  className: 'help-block',
  childView: View,
  childViewOptions: {
    className: 'help-block-message',
    template: function (model) {
        return `<i class="fa fa-exclamation-triangle fa-xs mr-1"></i>${i18next.t(model.message)}`;
    }
  }
}) {}