import { CollectionView } from 'backbone.marionette';
import {Radio} from 'backbone';
import CarPart from './CarPart';
import CarPartsCollection from '../collections/CarParts';
import i18next from 'i18next';

class CarParts extends CollectionView.extend({
  className: 'row mt-3 no-gutters results',
  childView: CarPart,
  collectionEvents: {
    'update': 'onCollectionUpdate'
  }
}) {
  initialize(options) {
    this.mergeOptions(options, ['initialSelection']);
    this.collection = new CarPartsCollection();
    this.channel = Radio.channel('typeSelection');
    this.data = {
      type: this.initialSelection.type,
      category: this.initialSelection.category,
      positionFrontRear: this.initialSelection.positionFrontRear,
      page: 1,
      language: i18next.language
    };
    this.fetch();
    this.listenTo(this.channel, 'change', this.onSelectionChange);
    this.render();
  }
  onSelectionChange(selection) {
    this.$el.empty();
    this.data = {
      type: selection.get('type'),
      category: selection.get('category'),
      positionFrontRear: selection.get('positionFrontRear'),
      page: 1,
      language: i18next.language
    };
    this.collection.reset();
    this.fetch();
    this.render();
    $(window).scrollTop(0);
  }
  fetch() {
    const remove = this.data.page === 1;
    this.triggerMethod('before:fetch', this);
    this.collection.fetch(
      {
        data: this.data,
        remove: remove,
        success: (collection) => {
          if (collection.length === 0) {
            this.triggerMethod('view:update', this);
          }
        }
      }
    );
  }
  onCollectionUpdate() {
    this.triggerMethod('view:update', this);
    if (this.data.page && this.data.page !== this.collection.pages) {
      this.data.page = this.data.page + 1;
      if (this.$el.height() + (this.$el.offset()).top < $(window).height()) {
        return this.fetch();
      }
      $(window).scroll(() => {
        if (Math.ceil($(window).scrollTop()) + $(window).height() >= this.$el.height() + (this.$el.offset()).top - 400) {
          $(window).off('scroll');
          this.fetch();
        }
      });
    }
  }
}

export default CarParts;
