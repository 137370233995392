import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import template from '../templates/basketTotals.hbs';

class BasketTotals extends View.extend({
  template: template,
  modelEvents: {
    'change': 'render'
  },
  ui: {
    emptyCart: '.empty-cart'
  },
  events: {
    'click @ui.emptyCart': 'emptyCart'
  }
}) {
  initialize() {
    this.basketChannel = Radio.channel('basket');
  }
  templateContext() {
    return {
      orderUrl: this.getOrderUrl()
    };
  }
  emptyCart() {
    this.basketChannel.request('removeAll');
  }
  getOrderUrl() {
    return $('#basket').data('order-url');
  }
}

export default BasketTotals;
