import {CollectionView} from 'backbone.marionette';
import {Radio} from 'backbone';
import CategoryListElement from './CategoryListElement';
import i18next from 'i18next';

class CategorySelector extends CollectionView.extend({
  template: _.noop,
  childView: CategoryListElement,
  collectionEvents: {
    'sync': 'render'
  },
  childViewEvents: {
    'click:element': 'clickElement'
  }
}) {
  initialize(options) {
    this.initialSelection = this.$el.data('initial-selection');

    this.type = this.initialSelection.type;
    this.category = this.initialSelection.category;

    this.channel = Radio.channel('typeSelection');
    this.listenTo(this.channel, 'change', this.onSelectionChange);

    this.categorySelectionChannel = Radio.channel('categorySelection');

    this.fetchCategories();
    this.render();
  }
  childViewOptions() {
    return {
      selectedCategory: this.category
    };
  }
  onSelectionChange(model) {
    let type = model.get('type');
    if (!type) {
      return;
    }
    this.type = type;
    this.category = model.get('category');
    this.fetchCategories();
  }
  clickElement(view) {
    const radio = view.getUI('radio');
    const label = view.getUI('label');
    this.categorySelectionChannel.trigger('change:category', {url: radio.data('url'), value: radio.val(), label: label.html()});
  }
  fetchCategories() {
    this.collection.fetch({
      data: {type: this.type, language: i18next.language},
      localCache: true,
      reset: true
    });
  }
}

export default CategorySelector;
