import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import Modal from '../../../behaviors/Modal';
import template from '../templates/selectCarTypesModal.hbs';

class SelectCarTypeModal extends View.extend({
  behaviors: {
    modal: {
      behaviorClass: Modal,
      modalClasses: 'fade'
    }
  },
  template: template,
  ui: {
    carType: '.car-type'
  },
  events: {
    'click @ui.carType': 'carTypeSelected'
  }
}) {
  initialize() {
    this.typeSelectionChannel = Radio.channel('typeSelection');
  }
  templateContext() {
    return {
      types: this.getOption('types')
    };
  }
  carTypeSelected(event) {
    const element = this.$el.find(event.currentTarget);
    const carTypeId = element.data('car-type-id');
    this.typeSelectionChannel.request('set:type', carTypeId);
    this.$el.modal('hide');
  }
}

export default SelectCarTypeModal;
