import {Model} from 'backbone';

class Category extends Model.extend({
  idAttribute: 'articleCategoryId',
  defaults: {
    translate: true
  }
}) {}

export default Category;
