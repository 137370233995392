import {Behavior} from 'backbone.marionette';
import {Collection} from 'backbone';
import {default as HelpBlockView} from '../extensions/formvalidator/views/HelpBlock';

export default class HelpBlock extends Behavior.extend({
}) {
  initialize() {
    this.validatorMessages = new Collection();
  }
  onClearValidatorMessages() {
    this.validatorMessages.reset();
    this.view.$el.removeClass('alert-danger rounded');
    if (this.view.getRegion('helpBlockAttachPoint').hasView()) {
      this.view.getChildView('helpBlockAttachPoint').destroy();
    } else {
      this.view.$el.find('.help-block-attach-point').empty();
    }
  }
  onShowValidatorMessages() {
    if (this.onHasValidatorMessages()) {
      this.view.$el.addClass('alert-danger rounded');
      this.view.showChildView('helpBlockAttachPoint', new HelpBlockView({collection: this.validatorMessages}));
    }
  }
  onShowValidatorMessage(message) {
    this.validatorMessages.reset();
    this.onAddValidatorMessage(message);
    this.onShowValidatorMessages();
  }
  onAddValidatorMessage(message) {
    if (typeof message  === 'string') {
      this.validatorMessages.add({message: message});
    } else {
      throw 'Message should be a plain string';
    }
  }
  onHasValidatorMessages() {
    return this.validatorMessages.length > 0;
  }
  onGetValidatorMessages() {
    return this.validatorMessages;
  }
}