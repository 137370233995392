var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            checked=\"checked\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input\n        type=\"radio\"\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedCategory") : depth0),"===",(depth0 != null ? lookupProperty(depth0,"articleCategoryId") : depth0),{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":19}}})) != null ? stack1 : "")
    + "        id=\"cat_"
    + alias4(((helper = (helper = lookupProperty(helpers,"articleCategoryId") || (depth0 != null ? lookupProperty(depth0,"articleCategoryId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"articleCategoryId","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":37}}}) : helper)))
    + "\"\n        value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"articleCategoryId") || (depth0 != null ? lookupProperty(depth0,"articleCategoryId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"articleCategoryId","hash":{},"data":data,"loc":{"start":{"line":7,"column":15},"end":{"line":7,"column":36}}}) : helper)))
    + "\"\n        name=\"category\"\n        class=\"select-category\"\n        data-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":25}}}) : helper)))
    + "\" />\n<label for=\"cat_"
    + alias4(((helper = (helper = lookupProperty(helpers,"articleCategoryId") || (depth0 != null ? lookupProperty(depth0,"articleCategoryId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"articleCategoryId","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":37}}}) : helper)))
    + "\" class=\"label-category\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"term") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":72}}}))
    + "</label>\n\n";
},"useData":true});