import {View} from 'backbone.marionette';
import {Collection} from 'backbone';
import i18next from 'i18next';
import HelpBlock from '../../../behaviors/HelpBlock';


export default class BaseElement extends View.extend({
  template: false,
  ui: {
    element: 'input'
  },
  events: {
    'focus @ui.element': 'onFocus',
    'change @ui.element': 'onChange'
  },
  isEmptyMessage: 'This field can\'t be empty',
  behaviors: [HelpBlock],
  regions: {
    helpBlockAttachPoint: '.help-block-attach-point'
  }
}) {
  initialize(options) {
    this.filters = [];
    this.validators = [];
    this.required = (options.required !== undefined) ? options.required : true;
    if (options.isEmptyMessage !== undefined) {
      this.isEmptyMessage = options.isEmptyMessage;
    }
    if (options.parent !== undefined) {
      this.parent = options.parent;
    }

    if (options.filtersConfig !== undefined) {
      this.addFiltersFromConfig(options.filtersConfig);
    }
    if (options.validatorsConfig !== undefined) {
      this.addValidatorsFromConfig(options.validatorsConfig);
    }
    this.filterOnChange = options.validateOnChange ? options.filterOnChange : true;
    this.validateOnChange = options.validateOnChange ? options.validateOnChange : true;

    if (options.region !== undefined) {
      this.getUI('element').data('region', options.region);
    }
  }
  clearValidatorMessages() {
    this.triggerMethod('clearValidatorMessages');
  }
  showValidatorMessage(message) {
    this.triggerMethod('showValidatorMessage', message);
  }
  addFiltersFromConfig(config) {
    config.forEach(function (filterConfigItem) {
      if (filterConfigItem.type === undefined) {
        throw 'No filter type provided in filter config';
      }
      const filter = new filterConfigItem.type();
      if (filterConfigItem.options !== undefined) {
        filter.setOptions(filterConfigItem.options);
      }
      this.addFilter(filter);
    }.bind(this));
  }
  addFilter(filter) {
    this.filters.push(filter);
  }
  addValidatorsFromConfig(config) {
    config.forEach(function (validatorConfigItem) {
      if (validatorConfigItem.type === undefined) {
        throw 'No validator type provided in validator config';
      }
      const validator = new validatorConfigItem.type();
      if (validatorConfigItem.options !== undefined) {
        validator.setOptions(validatorConfigItem.options);
      }
      this.addValidator(validator);
    }.bind(this));
  }
  addValidator(validator) {
    this.validators.push(validator);
  }
  onChange(event) {
    if (!this.isEmpty()) {
      if (this.filterOnChange && !this.validateOnChange) {
        this.filterElement();
      } else if (this.validateOnChange) {
        let values = {};
        if (this.parent !== undefined) {
          values = this.parent.getValues();
        }
        this.validateElement(values, false);
      }
    }
    this.triggerMethod('change:formelement', this);
  }
  onFocus(event) {
    this.triggerMethod('clearValidatorMessages');
    this.triggerMethod('focus:formelement', this);
  }
  filterElement() {
    var value = this.getValue();
    this.filters.forEach(function (filter) {
      value = filter.filter(value);
    }.bind(this));
    this.setValue(value);
  }
  validateElement(values, onSubmit) {
    this.triggerMethod('clearValidatorMessages');
    const validated = this.isValidWithoutMessages(values, onSubmit);
    this.triggerMethod('showValidatorMessages');
    return validated;
  }
  isValid(values, onSubmit) {
    this.triggerMethod('clearValidatorMessages');
    if ((typeof this.required == 'function' && this.required(values, onSubmit)) ||
      (typeof this.required == 'boolean' && this.required)
    ) {
      if (this.isEmpty()) {
        this.triggerMethod('showValidatorMessage', this.isEmptyMessage);
        return false;
      }
      return this.validateElement(values, onSubmit);
    } else if (typeof this.required == 'boolean' && !this.required && !this.isEmpty()) {
      return this.validateElement(values, onSubmit);
    }
    return true;
  }
  isValidWithoutMessages(values, onSubmit) {
    this.filterElement();

    var validated = true;

    var value = this.getValue();
    for (let i = 0; i < this.validators.length; i++) {
      const validator = this.validators[i];
      if (!validator.validate(value, values, onSubmit)) {
        validated = false;
        validator.getMessages().forEach(function (message) {
          this.triggerMethod('addValidatorMessage', message);
        }.bind(this));
        if (validator.breakChainOnFailure) {
          return validated;
        }
      }
    }
    return validated;
  }
  getName() {
    return this.getUI('element').attr('name');
  }
  getValue() {
    throw 'Not implemented';
  }
  setValue(value) {
    throw 'Not implemented';
  }
  isEmpty() {
    const value = this.getValue();
    return value === null || value === '';
  }
}

