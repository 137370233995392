import {View} from 'backbone.marionette';
import template from '../templates/Totals.hbs';

export default class Totals extends View.extend({
  modelEvents: {
    'change': 'onChangeModel'
  },
  template: template
}) {
  onChangeModel(model) {
    this.render();
  }
}