import {Behavior} from 'backbone.marionette';

class CheckboxCollapse extends Behavior.extend({
  events: {
    'show.bs.collapse @ui.checkboxCollapse': 'onShow',
    'shown.bs.collapse @ui.checkboxCollapse': 'onShown',
    'hide.bs.collapse @ui.checkboxCollapse': 'onHide',
    'hidden.bs.collapse @ui.checkboxCollapse': 'onHidden',
    'change @ui.checkbox': 'onChangeCheckbox'
  },
  ui: {
    checkboxCollapse: '.collapse.checkbox-collapse',
    checkbox: 'input[type="checkbox"][data-collapse-target]'
  }
}) {
  onChangeCheckbox(event) {
    const checkbox = $(event.currentTarget);
    const targetCollapse = checkbox.data('collapse-target');
    const checked = checkbox.prop('checked');
    let collapseState = 'show';
    if ((!checked && !checkbox.data('collapse-inverse')) || (checked && checkbox.data('collapse-inverse'))) {
      collapseState = 'hide';
    }
    $(targetCollapse).data('checkbox', checkbox.attr('id'));
    $(targetCollapse).collapse(collapseState);
  }
  onShow(event) {
    this.setState(this.getCheckbox(event), true);
  }
  onShown(event) {
    this.setState(this.getCheckbox(event), false);
  }
  onHide(event) {
    this.setState(this.getCheckbox(event), true);
  }
  onHidden(event) {
    this.setState(this.getCheckbox(event), false);
  }
  getCheckbox(event) {
    const checkboxSelector = $(event.currentTarget).data('checkbox');
    return $('#' + checkboxSelector);
  }
  setState(checkbox, disabled) {
    checkbox.prop('disabled', disabled);
  }
}

export default CheckboxCollapse;
