import Handlebars from 'handlebars/runtime';
import MoneyFormatter from '../../utils/MoneyFormatter';

function money(moneyObject) {
  let formattedMoney = MoneyFormatter.formatMoney(moneyObject);
  return new Handlebars.SafeString(formattedMoney);
}

function moneyEquals(moneyObject1, moneyObject2) {
  return moneyObject1.currency === moneyObject2.currency &&
    parseInt(moneyObject1.amount) === parseInt(moneyObject2.amount);
}

function moneyGreaterThen(moneyObject1, moneyObject2) {
  return moneyObject1.currency === moneyObject2.currency &&
    parseInt(moneyObject1.amount) > parseInt(moneyObject2.amount);
}

function moneyGreaterEqualsThen(moneyObject1, moneyObject2) {
  return moneyObject1.currency === moneyObject2.currency &&
    parseInt(moneyObject1.amount) >= parseInt(moneyObject2.amount);
}

function moneyEqualsZero(moneyObject) {
  return parseInt(moneyObject.amount) === 0;
}

function moneyGreaterThenZero(moneyObject) {
  return parseInt(moneyObject.amount) > 0;
}

function moneyGreaterEqualsThenZero(moneyObject) {
  return parseInt(moneyObject.amount) >= 0;
}

export {
  money,
  moneyEquals,
  moneyGreaterThen,
  moneyGreaterEqualsThen,
  moneyEqualsZero,
  moneyGreaterThenZero,
  moneyGreaterEqualsThenZero
};
