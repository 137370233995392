import {Behavior} from 'backbone.marionette';
import {Radio} from 'backbone';
import i18next from 'i18next';

class ColorCodeLocation extends Behavior.extend({
  ui: {
    locateColorCode: '.locate-color-code'
  },
  events: {
    'click @ui.locateColorCode': 'showColorCodeLocation'
  }
}) {
  showColorCodeLocation() {
    const root = $('body').data('root');
    Radio.channel('modal').request('showImageModal', {imageUrl: root + '/images/kleurcode_locaties_bw.jpg', imageTitle: i18next.t('kleurcode_locaties')});
  }
}

export default ColorCodeLocation;
