import { View } from 'backbone.marionette';
import CarParts from './CarParts';
import template from '../templates/partOverview.hbs';
import loadingTemplate from '../templates/loading.hbs';
import noPartsTemplate from '../templates/noParts.hbs';

class PartsOverview extends View.extend({
  template: template,
  childViewEvents: {
    'before:fetch': 'beforeFetch',
    'view:update': 'viewUpdate'
  },
  regions: {
    carParts: {el: '#products', replaceElement: true},
    message: '.parts-message'
  }
}) {
  initialize() {
    this.childViewClassName = 'col-12 text-center';
    this.initialSelection = this.$el.data('initial-selection');
    this.render();
    this.showChildView('carParts', new CarParts({initialSelection: this.initialSelection}));
  }
  beforeFetch() {
    const childView = this.getChildView('message');
    if (childView) {
      childView.destroy();
    }
    this.showChildView('message', new View({template: loadingTemplate, className: this.childViewClassName}));
  }
  viewUpdate(view) {
    const childView = this.getChildView('message');
    if (childView) {
      childView.destroy();
    }
    if (view.collection.length === 0) {
      this.showChildView('message', new View({template: noPartsTemplate, className: this.childViewClassName}));
    }
  }
}

export default PartsOverview;
