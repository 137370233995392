import {View} from 'backbone.marionette';
import template from '../templates/imageModal.hbs';
import Modal from '../../../behaviors/Modal';

class ImageModal extends View.extend({
  behaviors: {
    modal: {
      behaviorClass: Modal,
      modalClasses: 'fade'
    }
  },
  template: template
}) {
  templateContext() {
    return {
      imageUrl: this.getOption('imageUrl'),
      imageTitle: this.getOption('imageTitle')
    };
  }
}

export default ImageModal;
