import Marionette from 'backbone.marionette';
import Metadata from './models/Metadata';
import {Radio} from 'backbone';

class BasketService extends Marionette.Object.extend({
  channelName: 'basket',
  radioRequests: {
    addItems: 'addItems',
    removeItems: 'removeItems',
    removeAll: 'removeAll',
    updateItem: 'updateItem',
    getCount: 'getCount'
  }
}) {
  initialize(options) {
    console.log('Initializing Basket Service');
    this.url = '/api/basketitems';
    this.basketMetadata = new Metadata();
    this.modalChannel = Radio.channel('modal');
    this.listenTo(this.basketMetadata, 'change:count', this.onCountChange);
    this.getMetadata();
  }
  getCount() {
    return this.basketMetadata.get('count');
  }
  getMetadata(options) {
    let ajaxParams = {
      method: 'GET'
    };
    ajaxParams = _.extend(this.getAjaxParams(), ajaxParams, (options || {}));
    $.ajax(ajaxParams).done((data) => {
      this.basketMetadata.set(data, {silent: true});
    });
  }
  addItems(items, options) {
    items = !_.isArray(items) ? [items] : items.slice();
    let ajaxParams = {
      method: 'POST',
      data: JSON.stringify(items)
    };
    ajaxParams = _.extend(this.getAjaxParams(), ajaxParams, (options || {}));
    $.ajax(ajaxParams).done((data) => {
      this.basketMetadata.set(data);
      this.getChannel().trigger('item:added');
    });
  }
  removeItems(items, options) {
    const itemIds = !_.isArray(items) ? [items] : items.slice();
    let ajaxParams = {
      method: 'DELETE'
    };
    itemIds.forEach(itemId => {
      ajaxParams.url = this.url + '/' + itemId;
      ajaxParams = _.extend(this.getAjaxParams(), ajaxParams, (options || {}));
      $.ajax(ajaxParams).done((data) => {
        this.basketMetadata.set(data);
        this.getChannel().trigger('item:deleted');
      });
    });
  }
  removeAll(options) {
    let ajaxParams = {
      method: 'DELETE'
    };
    ajaxParams = _.extend(this.getAjaxParams(), ajaxParams, (options || {}));
    $.ajax(ajaxParams).done((data) => {
      this.basketMetadata.set(data);
      this.getChannel().trigger('item:deletedAll');
    });
  }
  updateItem(itemId, item, options) {
    let ajaxParams = {
      method: 'PUT',
      data: JSON.stringify(item),
      url: this.url + '/' + itemId
    };
    ajaxParams = _.extend(this.getAjaxParams(), ajaxParams, (options || {}));
    $.ajax(ajaxParams).done((data) => {
      this.basketMetadata.set(data);
      this.getChannel().trigger('item:updated');
    });
  }
  onCountChange() {
    const channel = this.getChannel();
    const prevCount = this.basketMetadata.previous('count');
    const currentCount = this.basketMetadata.get('count');
    if (prevCount < currentCount) {
      channel.trigger('items:added', currentCount - prevCount);
    } else if (prevCount > currentCount) {
      channel.trigger('items:removed', prevCount - currentCount);
    }
    if (prevCount !== currentCount) {
      channel.trigger('items:changed', currentCount);
    }
  }
  getAjaxParams() {
    return {
      url: this.url,
      dataType: 'json',
      contentType: 'application/json'
    };
  }
}

export default BasketService;
