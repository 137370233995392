import NodeModule from '../../services/NodeModule';
import PartDetail from './views/PartDetail';

class PartDetailNode extends NodeModule {
  start(options) {
    console.log('Starting Part Detail');
    const partDetail = new PartDetail({el: options.el});
  }
}

export default PartDetailNode;
