var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-body\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"sluiten",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":130},"end":{"line":4,"column":145}}}))
    + "</span></button>\n            <img src=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"imageUrl") || (depth0 != null ? lookupProperty(depth0,"imageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":34}}}) : helper)))
    + "\" alt=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"imageTitle") || (depth0 != null ? lookupProperty(depth0,"imageTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"imageTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":55}}}) : helper)))
    + "\" class=\"imagepreview\" style=\"width: 100%;\" >\n        </div>\n    </div>\n</div>\n";
},"useData":true});