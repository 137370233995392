import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import SelectCarTypeModal from './SelectCarTypeModal';
import Stock from '../../../behaviors/Stock';
import Popover from '../../../utils/Popover';
import i18next from 'i18next';
import ColorCodeLocation from '../../../behaviors/ColorCodeLocation';
import ColorCodeAutocomplete from '../../../behaviors/ColorCodeAutocomplete';
import Money from 'js-money';
import MoneyFormatter from '../../../utils/MoneyFormatter';
import ButtonHelper from '../../../utils/ButtonHelper';

class PartDetail extends View.extend({
  template: _.noop,
  behaviors: [Stock, ColorCodeLocation, ColorCodeAutocomplete],
  ui: {
    orderPartDetail: '.order-part-detail',
    colorCode: '.color-code',
    sprayPart: '#spray-part',
    choseSideInput: '.chose-side-input',
    shopLink: '.shop-link',
    firstCollapse: '#first-collapse',
    partName: '.part-name',
    showLess: '.show-less',
    showMore: '.show-more'
  },
  events: {
    'click @ui.orderPartDetail': 'orderPart',
    'change @ui.sprayPart': 'onChangeSprayPart',
    'change @ui.choseSideInput': 'onSideChosen',
    'hidden.bs.collapse @ui.firstCollapse': 'onHideCollapse',
    'shown.bs.collapse @ui.firstCollapse': 'onShowCollapse'
  }
}) {
  initialize(options) {
    this.basketChannel = Radio.channel('basket');
    this.modalChannel = Radio.channel('modal');
    this.carPartId = this.getCarPartId();
    this.selectedType = this.getSelectedType();
    this.typeSelectionChannel = Radio.channel('typeSelection');
    this.listenToOnce(this.typeSelectionChannel, 'change:type', this.onTypeChange);
    this.render();
    this.setSides();
    this.setSprayPart();
  }

  onHideCollapse() {
    this.getUI('showLess').addClass('d-none');
    this.getUI('showMore').removeClass('d-none');

    $('html,body').animate({
      scrollTop: this.getUI('partName').offset().top
    }, 350);
  }
  onShowCollapse() {
    this.getUI('showMore').addClass('d-none');
    this.getUI('showLess').removeClass('d-none');
  }
  getCarPartId() {
    return this.$el.data('car-part-id');
  }
  getSelectedType() {
    const typeNode = this.$el.find('.models .model.selected').first();
    if (typeNode) {
      return typeNode.data('car-type-id');
    }
  }
  orderPart() {
    if (!this.selectedType) {
      this.modalChannel.request('showModal', new SelectCarTypeModal({
        types: this.getCarTypes()
      }));
      return;
    }
    if (this.$el.find('.chose-side').length && this.$el.find('.chose-side-input:checked').length === 0) {
      Popover.popover(this.getUI('choseSideInput').first().next(), i18next.t('selecteer_een_zijde'), 'warning');
      Popover.popover(this.getUI('choseSideInput').last().next(), i18next.t('selecteer_een_zijde'), 'warning');
      return;
    }
    let colorCode = null;
    if (this.$el.find('.spray-part-input:checked').length) {
      const input = this.getUI('colorCode');
      colorCode = input.val();
      if (!colorCode) {
        Popover.popover(input, i18next.t('vul_een_kleurcode_in'), 'warning');
        return;
      }
    }
    this.getUI('orderPartDetail')
      .addClass('disabled')
      .children('.button-icon')
      .addClass("fa-circle-o-notch fa-spin")
      .removeClass("fa-shopping-cart");
    const items = [];
    const chosenSides = this.$el.find('.chose-side-input:checked');
    if (chosenSides.length !== 0) {
      chosenSides.each(function(index, element) {
        items.push({articleId: $(element).data('car-part-id')});
      });
    } else {
      items.push(
        {articleId: this.carPartId}
      );
    }
    _.each(items, function(item) {
      item.articleType = 'pp';
      item.quantity = 1;
      item.colorCode = colorCode;
      item.carTypeId = this.selectedType;
    }, this);
    this.basketChannel.request('addItems', items, {
      success: (data) => {
        this.reset();
        if (data.added > 0) {
          ButtonHelper.blinkSuccess(this.getUI('orderPartDetail'));
          Radio.channel('modal').request('showBasketUpdateModal', data.count, data.basketUrl);
        } else {
          ButtonHelper.blinkDanger(this.getUI('orderPartDetail'));
        }
      },
      error: (data) => {
        this.reset();
        ButtonHelper.blinkDanger(this.getUI('orderPartDetail'));
      }
    });
  }
  getCarTypes() {
    const types = [];
    this.$el.find('.models .model').each(function(index, type) {
      type = $(type);
      types.push({
        name: type.html(),
        carTypeId: type.data('car-type-id'),
        url: type.data('url')
      });
    });
    return types;
  }
  onTypeChange(type) {
    this.selectedType = type;
    const element = this.$el.find('.models .model[data-car-type-id="' + type + '"]');
    element.addClass('selected text-primary');
    this.getUI('shopLink').attr('href', element.data('url'));
    this.orderPart();
  }
  onChangeSprayPart() {
    if (this.getUI('sprayPart').is(':checked')) {
      this.$el.find('.total-spray-price').removeClass('text-gray-light').addClass('text-primary');
      this.$el.find('.spray-price-line').addClass('border-primary');
      this.$el.find('.car-part-price').addClass('text-gray-light').removeClass('text-primary');
      this.$el.find('.car-part-price-line').removeClass('border-primary');
      this.getUI('colorCode').removeAttr('disabled');
    } else {
      this.$el.find('.car-part-price').removeClass('text-gray-light').addClass('text-primary');
      this.$el.find('.car-part-price-line').addClass('border-primary');
      this.$el.find('.total-spray-price').addClass('text-gray-light').removeClass('text-primary');
      this.$el.find('.spray-price-line').removeClass('border-primary');
      this.getUI('colorCode').attr('disabled', true);
    }
  }
  onSideChosen() {
    const double = this.$el.find('.chose-side-input:checked').length > 1;
    this.$el.find('.money').each(function(index, element) {
      element = $(element);
      const money = Money.fromInteger(element.data('money'));
      element.html(MoneyFormatter.formatMoney(double ? money.multiply(2) : money));
    });
  }
  setSides() {
    const element = this.$el.find('.chose-side').first();
    if (!element) {
      return;
    }
    const sides = element.data('sides');
    if (sides && sides.length > 0) {
      if (sides.indexOf('r') > -1) {
        this.$el.find('.chose-side-input-right').first().attr('checked', true);
      }
      if (sides.indexOf('l') > -1) {
        this.$el.find('.chose-side-input-left').first().attr('checked', true);
      }
      this.onSideChosen();
    }
  }
  setSprayPart() {
    const element = this.$el.find('.spray-part[data-spray-part]').first();
    if (element) {
      const sprayPart = element.data('spray-part');
      this.$el.find('.spray-part-input').first().attr('checked', sprayPart);
      this.onChangeSprayPart();
    }
  }
  reset() {
    this.getUI('orderPartDetail')
      .removeClass('disabled')
      .children('.button-icon')
      .removeClass("fa-circle-o-notch fa-spin")
      .addClass("fa-shopping-cart");
  }
}

export default PartDetail;
