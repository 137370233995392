import {Behavior} from 'backbone.marionette';
import {Radio} from 'backbone';

class ImageModal extends Behavior.extend({
  ui: {
    imageModal: '.image-modal'
  },
  events: {
    'click @ui.imageModal': 'showModal'
  }
}) {
  showModal(event) {
    const link = $(event.currentTarget);
    const image = link.find('> img');
    Radio.channel('modal').request('showImageModal',  {imageUrl: image.data('image-modal'), imageTitle: image.attr('alt')});
  }
}

export default ImageModal;
