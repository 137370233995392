import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import template from '../templates/relatedParts.hbs';
import Modal from '../../../behaviors/Modal';

class RelatedPartsModal extends View.extend({
  behaviors: {
    modal: {
      behaviorClass: Modal,
      modalClasses: 'fade'
    }
  },
  template: template,
  ui: {
    geeversPart: '.geevers-part',
    orderGeeversParts: '.order-geevers-parts'
  },
  events: {
    'click @ui.geeversPart': 'onPartClick',
    'click @ui.orderGeeversParts': 'orderGeeversParts'
  }
}) {
  templateContext() {
    return {
      data: this.getOption('data'),
    };
  }
  onPartClick(event) {
    const element = this.$el.find(event.currentTarget);
    element.toggleClass('active');
    const activeElements = this.$el.find('a.geevers-part.active');
    const button = this.getUI('orderGeeversParts');
    if (activeElements.length > 0) {
      button.removeAttr('disabled');
    } else {
      button.attr('disabled', true);
    }
    button.find('.parts-count').first().html('(' + activeElements.length + ')');
  }
  orderGeeversParts() {
    this.getUI('orderGeeversParts')
      .addClass('disabled')
      .append('<span class="loading"><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></span>');
    const items = [];
    const carTypeId = this.getOption('carTypeId');
    this.$el.find('a.geevers-part.active').each(function(index, element) {
      var el = $(element);
      var carPartId = el.data('car-part-id');
      el.removeClass('active');
      items.push({
        articleId: carPartId,
        carTypeId: carTypeId,
        quantity: 1,
        articleType: 'ge'
      });
    });
    const channel = Radio.channel('basket');
    channel.request('addItems', items, {
      complete: () => {
        this.$el.modal('hide');
      },
    });
  }
}

export default RelatedPartsModal;
