import {View} from 'backbone.marionette';
import {Radio} from 'backbone';

class Badge extends View.extend({}) {
  initialize() {
    this.basketChannel = Radio.channel('basket');
    this.listenTo(this.basketChannel, 'items:changed', this.onItemsChanged);
  }
  onItemsChanged(count) {
    var element = this.$el.find('.badge');
    if (element.hasClass('animate')) {
      element.removeClass('animate');
    }
    var classNames = element.attr('class') + ' animate';
    element.remove();
    this.$el.find('sup').append('<span class="' + classNames + '">' + count + '</span>');
  }
}

export default Badge;
