import BaseValidator from './BaseValidator';

export default class PostalCode extends BaseValidator {
  constructor() {
    super({
      postalCodeNotValidNL: 'Not a valid Dutch postal code',
      postalCodeNotValidBE: 'Not a valid Belgian postal code',
      postalCodeNotValidDE: 'Not a valid German postal code',
      missingToken: 'No token was provided to match against'
    });
    this.regex = {
      NL: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
        BE: /^[1-9]{1}[0-9]{3}$/,
        DE: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/
    };
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    if (!onSubmit) {
      return true;
    }
    if (values[this.token] === undefined) {
      this.addMessage(this.validationMessages.missingToken);
      return false;
    }
    const country = values[this.token];
    if (this.regex[country] === undefined) {
      return true;
    }
    const valid = this.regex[country].test(value);
    if (!valid) {
      this.addMessage(this.validationMessages['postalCodeNotValid' + country]);
    }
    return valid;
  }
  setOptions(options) {
    super.setOptions(options);
    if (options.token === undefined) {
      throw 'No token provided to compare against'
    }
    this.token = options.token;
  }
}