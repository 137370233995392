import TypeSelector from './views/TypeSelector';
import NodeModule from '../../services/NodeModule';
import CarTypes from './collections/CarTypes';
import Categories from './collections/Categories';
import BrandSelect from './views/BrandSelect';
import Select from './views/Select';

class TypeSelectorNode extends NodeModule {
  start(options) {
    console.log('Starting Type Selector');
    const carTypes = new CarTypes();
    const categories = new Categories();
    const typeSelector = new TypeSelector({el: options.el, carTypes: carTypes, categories: categories});
    const brandSelect = new BrandSelect({el: '#brand-select'});
    const typeSelect = new Select({el: '#type-select', collection: carTypes});
    const categorySelect = new Select({el: '#category-select', collection: categories});
  }
}

export default TypeSelectorNode;
