import NodeModule from '../../services/NodeModule';
import ReviewList from './views/ReviewList';

class ReviewListNode extends NodeModule {
  start(options) {
    console.log('Starting Review List');
    const reviewList = new ReviewList({el: options.el});
  }
}

export default ReviewListNode;
