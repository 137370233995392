import NodeModule from '../../services/NodeModule';
import Badge from './views/Badge';

class BasketBadgeNode extends NodeModule {
  start(options) {
    console.log('Starting Basket Badge');
    const badge = new Badge({el: options.el});
  }
}

export default BasketBadgeNode;
