import {View} from 'backbone.marionette';
import template from '../templates/basketUpdate.hbs';
import Modal from '../../../behaviors/Modal';

class BasketUpdateModal extends View.extend({
  behaviors: {
    modal: {
      behaviorClass: Modal,
      modalClasses: 'fade',
      delay: 4000
    }
  },
  template: template,
  ui: {
    badge: '.badge'
  },
  events: {
    'shown.bs.modal': 'triggerShown'
  }
}) {
  templateContext() {
    return {
      count: this.getOption('count'),
      basketUrl: this.getOption('basketUrl')
    };
  }
  triggerShown() {
    this.getUI('badge').addClass('animate');
  }
}

export default BasketUpdateModal;
