import BaseValidator from './BaseValidator';

export default class StringLength extends BaseValidator {
  constructor() {
    super({
      tooShort: "The input is less than %min% characters long",
      tooLong: "The input is more than %max% characters long"
    });
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    let message;
    if (value.length < this.min) {
      this.addMessage(this.validationMessages.tooShort.replace('%min%', this.min));
      return false;
    }
    if (this.max !== undefined && value.length > this.max) {
      this.addMessage(this.validationMessages.tooLong.replace('%max%', this.max));
      return false;
    }
    return true;
  }
  setOptions(options) {
    super.setOptions(options);
    this.min = options.min ? options.min : 0;
    if (options.max !== undefined) {
      if (this.min > this.max) {
        throw '"min" value bigger than "max" value';
      }
      this.max = options.max;
    }
  }
}