import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import BreadcrumbModel from '../models/Breadcrumb';
import i18next from 'i18next';

class Breadcrumb extends View.extend({
  ui: {
    breadcrumb: '.breadcrumb'
  }
}) {
  initialize() {
    this.typeSelectionChannel = Radio.channel('typeSelection');
    this.listenTo(this.typeSelectionChannel, 'change', this.onSelectionChange);
  }
  onSelectionChange(model) {
    const type = model.get('type');
    if (!type) {
      return;
    }
    this.model = new BreadcrumbModel();
    this.listenTo(this.model, 'sync', this.onModelSync);
    this.model.fetch({ data: {
        language: i18next.language,
        type: type,
        category: model.get('category')
      }});
  }
  onModelSync(model) {
    this.$el.find('.breadcrumb-item.breadcrumb-item-category').remove();
    const carBrand = model.get('carBrand');
    this.$el.find('.breadcrumb-item.breadcrumb-item-brand a').attr('href', carBrand.url).html(carBrand.label);
    const carType = model.get('carType');
    this.$el.find('.breadcrumb-item.breadcrumb-item-type a').attr('href', carType.url).html(carType.label);
    const category = model.get('category');
    if (category) {
      this.getUI('breadcrumb').append('<li class="breadcrumb-item breadcrumb-item-category"><a href="' + category.url + '">' + category.label + '</a></li>');
    }
  }
}

export default Breadcrumb;