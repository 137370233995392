import {CollectionView, View} from 'backbone.marionette';
import template from '../templates/CostOptions.hbs'
import CostOption from './CostOption';

export default class CostOptions extends CollectionView.extend({
  template: template,
  childViewContainer: '.card-group-toggle',
  childView: CostOption,
  emptyView: View,
  childViewEventPrefix: 'childview'
}) {
  templateContext() {
    return {
      elementId: this.$el.data('id')
    };
  }
  childViewOptions(model) {
    return {
      elementName: this.$el.data('name')
    };
  }
  getType() {
    return this.$el.data('type');
  }
  onChildviewInputSelected(view) {
    this.children.each(function(childView) {
      if (childView.model.get('selected') !== (childView === view)) {
        childView.model.set('selected', childView === view);
        childView.update(childView === view);
      }
    });
  }
}