import { Collection } from 'backbone';

import Review from '../models/Review';
import { mapResponse } from '../util';

export default class Reviews extends Collection.extend({
  url: '/api/product-reviews',
  model: Review
}) {
  parse(response) {
    return mapResponse(response);
  }

  queryItems({ productIds } = {}) {
    return this.fetch({
      data: { product_ids: productIds }
    });
  }
}
