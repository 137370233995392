import {Model} from 'backbone';

class CarType extends Model.extend({
  idAttribute: 'carTypeId',
  urlRoot: '/api/cartypes',
  defaults: {
    translate: false
  }
}) {}

export default CarType;
