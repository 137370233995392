import FormValidator from '../../../extensions/formvalidator/FormValidator';
import TextElement from '../../../extensions/formvalidator/element/TextElement';
import StringReplace from '../../../extensions/formvalidator/filter/StringReplace';
import Trim from '../../../extensions/formvalidator/filter/Trim';
import Case from '../../../extensions/formvalidator/filter/Case';
import Email from '../../../extensions/formvalidator/validator/Email';
import SelectElement from '../../../extensions/formvalidator/element/SelectElement';
import Digits from '../../../extensions/formvalidator/validator/Digits';
import GreaterThan from '../../../extensions/formvalidator/validator/GreaterThan';
import StringLength from '../../../extensions/formvalidator/validator/StringLength';
import Identical from '../../../extensions/formvalidator/validator/Identical';

export default class UserLostPasswordFormValidator extends FormValidator.extend({}) {
  initialize(options) {
    super.initialize(options);

    this.formElementsConfig = {
      email: {type: TextElement, options: {el: '#form-group-userEmail', isEmptyMessage: 'vul_uw_emailadres_in', filtersConfig: [{type: Trim}], validatorsConfig: [{type: Email, options: {validationMessages: {emailNotValid: 'het_emailadres_heeft_een_onjuist_formaat'}}}]}},
    };

    this.setFormElementsFromConfig(this.formElementsConfig);
  }
}