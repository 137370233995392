import {View} from 'backbone.marionette';
import ImageModal from '../../../behaviors/ImageModal';

class ImageCarousel extends View.extend({
  template: _.noop,
  behaviors: [ImageModal],
  ui: {
    mainImage: '.main-image',
    linkThumbnail: '.link-thumbnail',
  },
  events: {
    'click @ui.linkThumbnail': 'setImage'
  }
}) {
  setImage(event) {
    const link = $(event.currentTarget);
    const sourceImage = link.find('> img');
    const targetImage = this.getUI('mainImage');
    targetImage.attr('src', sourceImage.data('image-large'));
    targetImage.data('image-modal', sourceImage.data('image-modal'));
  }
}

export default ImageCarousel;