/**
 * Format date
 * @param {string} date
 */
export const formatDate = (date) => {
  const d = new Date(date);

  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

export const getStars = (score = 0) => {
  if (!score) {
    return ['fa-star-o', 'fa-star-o', 'fa-star-o', 'fa-star-o', 'fa-star-o'];
  }

  const stars = [];

  // Map 10-rating system to 5-rating system
  const fullStars = Math.floor(score / 2);
  const halfStars = score % 2;
  const emptyStars = 5 - fullStars - halfStars;

  for (let i = 0; i < fullStars; i++) {
    stars.push('fa-star');
  }

  for (let i = 0; i < halfStars; i++) {
    stars.push('fa-star-half-o');
  }

  for (let j = 0; j < emptyStars; j++) {
    stars.push('fa-star-o');
  }

  return stars;
};

export const mapResponse = (response) => {
  const { aggregate, product_reviews } = response;
  const reviews = product_reviews
    .map((review) => {
      return Object.assign(review, {
        stars: getStars(review.score),
        created_at: formatDate(review.created_at),
      })
    })
  ;

  const aggregateDefault = {
    average_stars: [],
    stars: [],
  };

  const processedData = {
    aggregate: Object.assign(aggregateDefault, aggregate),
    aggregate_label: `review${aggregate.amount_of_reviews > 1 ? 's' : ''}`,
    product_reviews: reviews,
    link_to_all_reviews: '#'
  };

  aggregate.average_stars = (aggregate.average_score / 2).toFixed(1);
  aggregate.stars = getStars(Math.round(aggregate.average_score));

  return Object.assign(
    processedData,
    response
  );
};
