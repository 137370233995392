import FormValidator from '../../../extensions/formvalidator/FormValidator';
import TextElement from '../../../extensions/formvalidator/element/TextElement';
import StringReplace from '../../../extensions/formvalidator/filter/StringReplace';
import Trim from '../../../extensions/formvalidator/filter/Trim';
import Case from '../../../extensions/formvalidator/filter/Case';
import Email from '../../../extensions/formvalidator/validator/Email';
import SelectElement from '../../../extensions/formvalidator/element/SelectElement';
import Digits from '../../../extensions/formvalidator/validator/Digits';
import GreaterThan from '../../../extensions/formvalidator/validator/GreaterThan';
import StringLength from '../../../extensions/formvalidator/validator/StringLength';
import Identical from '../../../extensions/formvalidator/validator/Identical';

export default class UserDataPasswordFormValidator extends FormValidator.extend({}) {
  initialize(options) {
    super.initialize(options);

    this.formElementsConfig = {
      passwordCurrent: {type: TextElement, options: {el: '#form-group-userPasswordCurrent', isEmptyMessage: 'voer_een_wachtwoord_in'}},
      password: {type: TextElement, options: {el: '#form-group-userPassword', isEmptyMessage: 'voer_een_wachtwoord_in', validatorsConfig: [{type: StringLength, options: {min: 10, validationMessages: {tooShort: 'het_wachtwoord_moet_tenminste_10_tekens_bevatten'}}}]}},
      passwordConfirm: {type: TextElement, options: {el: '#form-group-userPasswordConfirm', isEmptyMessage: 'voer_een_wachtwoord_in', validatorsConfig: [{type: Identical, options: {token: 'password', validationMessages: {notSame: 'de_wachtwoorden_zijn_niet_identiek'}}}]}}
    };

    this.setFormElementsFromConfig(this.formElementsConfig);
  }
}