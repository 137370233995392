import {View} from 'backbone.marionette';
import template from '../templates/CostOption.hbs'

export default class CostOption extends View.extend({
  template: template,
  ui: {
    label: 'label.card',
    cardHeader: '.card-header',
    checkIcon: '.check-icon'
  },
  triggers: {
    'change input:radio': {event: 'input:selected', stopPropagation: false}
  }
}) {
  initialize(options) {
    this.elementName = options.elementName;
  }
  templateContext() {
    return {
      elementName: this.elementName
    };
  }
  update(active) {
    this.getUI('label').toggleClass('active border-success border-2', active);
    this.getUI('cardHeader').toggleClass('text-success border-success', active);
    this.getUI('cardHeader').toggleClass('text-muted', !active);
    this.getUI('checkIcon').toggleClass('fa fa-check-circle', active);
    this.getUI('checkIcon').toggleClass('far fa-circle text-gray-light', !active);
  }
}