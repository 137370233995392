import BaseValidator from './BaseValidator';

export default class Identical extends BaseValidator {
  constructor() {
    super({
      notSame: 'The two given tokens do not match',
      missingToken: 'No token was provided to match against'
    });
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    if (values[this.token] === undefined) {
      this.addMessage(this.validationMessages.missingToken);
      return false;
    }
    if (values[this.token] !== value) {
      this.addMessage(this.validationMessages.notSame);
      return false;
    }
    return true;
  }
  setOptions(options) {
    super.setOptions(options);
    if (options.token === undefined) {
      throw 'No token provided to compare against'
    }
    this.token = options.token;
  }
}