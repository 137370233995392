import PositionSelector from './views/PositionSelector';
import NodeModule from '../../services/NodeModule';

class PositionSelectorNode extends NodeModule {
  start(options) {
    console.log('Starting Position Selector');
    const positionSelector = new PositionSelector({el: options.el});
  }
}

export default PositionSelectorNode;
