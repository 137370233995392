import FormValidator from '../../../extensions/formvalidator/FormValidator';
import TextElement from '../../../extensions/formvalidator/element/TextElement';
import StringReplace from '../../../extensions/formvalidator/filter/StringReplace';
import Trim from '../../../extensions/formvalidator/filter/Trim';
import Case from '../../../extensions/formvalidator/filter/Case';
import Email from '../../../extensions/formvalidator/validator/Email';
import SelectElement from '../../../extensions/formvalidator/element/SelectElement';
import Digits from '../../../extensions/formvalidator/validator/Digits';
import GreaterThan from '../../../extensions/formvalidator/validator/GreaterThan';
import StringLength from '../../../extensions/formvalidator/validator/StringLength';
import Identical from '../../../extensions/formvalidator/validator/Identical';
import PostalCode from '../../../extensions/formvalidator/validator/PostalCode';
import NotIdentical from '../../../extensions/formvalidator/validator/NotIdentical';
import VatCode from '../../../extensions/formvalidator/validator/VatCode';

export default class UserRegistrationFormValidator extends FormValidator.extend({}) {
  initialize(options) {
    super.initialize(options);

    this.formElementsConfig = {

      password: {type: TextElement, options: {el: '#form-group-userPassword', isEmptyMessage: 'voer_een_wachtwoord_in', validatorsConfig: [{type: StringLength, options: {min: 10, validationMessages: {tooShort: 'het_wachtwoord_moet_tenminste_10_tekens_bevatten'}}}]}},
      passwordConfirm: {type: TextElement, options: {el: '#form-group-userPasswordConfirm', isEmptyMessage: 'voer_een_wachtwoord_in', validatorsConfig: [{type: Identical, options: {token: 'password', validationMessages: {notSame: 'de_wachtwoorden_zijn_niet_identiek'}}}]}},
      email: {type: TextElement, options: {el: '#form-group-userEmail', isEmptyMessage: 'vul_uw_emailadres_in', filtersConfig: [{type: Trim}], validatorsConfig: [{type: Email, options: {validationMessages: {emailNotValid: 'het_emailadres_heeft_een_onjuist_formaat'}}}]}},
      firstName: {type: TextElement, options: {el: '#form-group-userFirstName', isEmptyMessage: 'vul_uw_voornaam_in'}},
      lastName: {type: TextElement, options: {el: '#form-group-userLastName', isEmptyMessage: 'vul_uw_achternaam_in'}},
      company: {type: TextElement, options: {el: '#form-group-userCompany', required: false}},

      postalCode: {type: TextElement, options: {el: '#form-group-addressPostalCode', isEmptyMessage: 'vul_de_postcode_in', filtersConfig: [{type: StringReplace, options: {searchValue: ' ', newValue: ''}}, {type: Case, options: {caseType: 'upper'}}, {type: Trim}], validatorsConfig: [{type: PostalCode, options: {token: 'country', validationMessages: {postalCodeNotValidNL: 'ongeldig_nederlands_postcode', postalCodeNotValidBE: 'ongeldig_belgisch_postcode', postalCodeNotValidDE: 'ongeldig_duits_postcode'}}}]}},
      houseNumber: {type: TextElement, options: {el: '#form-group-addressHouseNumber', isEmptyMessage: 'vul_het_huisnummer_in', validatorsConfig: [{type: Digits, options: {breakChainOnFailure: true, validationMessages: {notDigits: 'vul_hier_een_geheel_getal_in'}}}, {type: GreaterThan, options: {min: '0', validationMessages: {notGreater: 'huisnummer_moet_vanaf_1_beginnen'}}}]}},
      houseNumberAddition: {type: TextElement, options: {el: '#form-group-addressHouseNumberAddition', required: false, validatorsConfig: [
            {type: StringLength, options: {max: 20, validationMessages: {tooLong: 'teveel_tekens_opgegeven'}}},
            {type: NotIdentical, options: {token: 'street', validationMessages: {same: 'kan_niet_gelijk_zijn_aan_straatnaam'}}},
            {type: NotIdentical, options: {token: 'houseNumber', validationMessages: {same: 'kan_niet_gelijk_zijn_aan_huisnummer'}}}
          ]}},
      street: {type: TextElement, options: {el: '#form-group-addressStreet', isEmptyMessage: 'vul_de_straat_in'}},
      city: {type: TextElement, options: {el: '#form-group-addressCity', isEmptyMessage: 'vul_de_woonplaats_in'}},
      country: {type: SelectElement, options: {el: '#form-group-addressCountry', isEmptyMessage: 'kies_uw_land', updateOnChange: true}},

      phoneNumber: {type: TextElement, options: {el: '#form-group-userPhoneNumber', isEmptyMessage: 'vul_uw_telefoonnummer_in'}},
      website: {type: TextElement, options: {el: '#form-group-userWebsite', required: false}},
      chamberOfCommerce: {type: TextElement, options: {el: '#form-group-userChamberOfCommerce', required: false}},
      vatCode: {type: TextElement, options: {el: '#form-group-userVatCode', required: false, filtersConfig: [{type: StringReplace, options: {searchValue: ' ', newValue: ''}}, {type: Case, options: {caseType: 'upper'}}, {type: Trim}], validatorsConfig: [{type: VatCode, options: {token: 'country', validationMessages: {vatCodeNotValidBE: 'ongeldig_belgisch_btw_code', vatCodeNotValidDE: 'ongeldig_duits_btw_code', vatCodeNotValidNL: 'ongeldig_nederlands_btw_code'}}}]}},

      addressDeliveryPostalCode: {type: TextElement, options: {el: '#form-group-addressDeliveryPostalCode', required: false, isEmptyMessage: 'vul_de_postcode_in', filtersConfig: [{type: StringReplace, options: {searchValue: ' ', newValue: ''}}, {type: Case, options: {caseType: 'upper'}}, {type: Trim}], validatorsConfig: [{type: PostalCode, options: {token: 'addressDeliveryCountry', validationMessages: {postalCodeNotValidNL: 'ongeldig_nederlands_postcode', postalCodeNotValidBE: 'ongeldig_belgisch_postcode', postalCodeNotValidDE: 'ongeldig_duits_postcode'}}}]}},
      addressDeliveryHouseNumber: {type: TextElement, options: {el: '#form-group-addressDeliveryHouseNumber', required: false, isEmptyMessage: 'vul_het_huisnummer_in', validatorsConfig: [{type: Digits, options: {breakChainOnFailure: true, validationMessages: {notDigits: 'vul_hier_een_geheel_getal_in'}}}, {type: GreaterThan, options: {min: '0', validationMessages: {notGreater: 'huisnummer_moet_vanaf_1_beginnen'}}}]}},
      addressDeliveryHouseNumberAddition: {type: TextElement, options: {el: '#form-group-addressDeliveryHouseNumberAddition', required: false, validatorsConfig: [
            {type: StringLength, options: {max: 20, validationMessages: {tooLong: 'teveel_tekens_opgegeven'}}},
            {type: NotIdentical, options: {token: 'addressDeliveryStreet', validationMessages: {same: 'kan_niet_gelijk_zijn_aan_straatnaam'}}},
            {type: NotIdentical, options: {token: 'addressDeliveryHouseNumber', validationMessages: {same: 'kan_niet_gelijk_zijn_aan_huisnummer'}}}
          ]}},
      addressDeliveryStreet: {type: TextElement, options: {el: '#form-group-addressDeliveryStreet', required: false, isEmptyMessage: 'vul_de_straat_in'}},
      addressDeliveryCity: {type: TextElement, options: {el: '#form-group-addressDeliveryCity', required: false, isEmptyMessage: 'vul_de_woonplaats_in'}},
      addressDeliveryCountry: {type: SelectElement, options: {el: '#form-group-addressDeliveryCountry', required: false, isEmptyMessage: 'kies_uw_land', updateOnChange: true}},
    };

    this.setFormElementsFromConfig(this.formElementsConfig);
  }
}