import CurrencyFormatter from 'currency-formatter';

class MoneyFormatter {
  static formatMoney(moneyObject) {
    return CurrencyFormatter.format(
      moneyObject.amount / 100,
      {
        code: moneyObject.currency,
        thousand: '.',
        format: '%s %v'
      }
    );
  }
}

export default MoneyFormatter;
