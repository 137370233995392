import {CollectionView, View} from 'backbone.marionette';
import Option from './Option';
import emptyOptionTemplate from '../templates/emptyOption.hbs';

class Select extends CollectionView.extend({
  tagName: 'select',
  template: _.noop,
  childView: Option,
  emptyView: View,
  emptyViewOptions: {
    tagName: 'option',
    template: emptyOptionTemplate
  },
  collectionEvents: {
    'sync reset': 'render'
  }
}) {
  initialize(options) {
    this.render();
  }
  render() {
    super.render();
    if (this.collection.length === 0) {
      this.$el.attr('disabled', 'disabled');
    } else {
      this.$el.append('<optgroup class="d-sm-none" label=""></optgroup>');
      this.$el.removeAttr('disabled').trigger('focus');
    }
  }
}

export default Select;
