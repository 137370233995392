import NodeModule from '../../services/NodeModule';
import Breadcrumb from './views/Breadcrumb';

class BreadcrumbNode extends NodeModule {
  start(options) {
    console.log('Starting Breadcrumb');
    const breadcrumb = new Breadcrumb({el: options.el});
  }
}

export default BreadcrumbNode;