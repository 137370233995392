import {Model} from 'backbone';
import Totals from './Totals';
import CostOptions from '../collections/CostOptions';

export default class Order extends Model.extend({
  url: '/api/order/get'
}) {
  initialize() {
    this.totals = new Totals();
    this.shippingOptions = new CostOptions();
    this.paymentOptions = new CostOptions();
  }
  parse(data) {
    this.shippingOptions.reset(data.shippingCostOptions);
    this.paymentOptions.reset(data.paymentCostOptions)
    this.totals.set(data.orderTotals);
  }
}