import {Collection} from 'backbone';
import CarPart from '../models/CarPart';

class CarParts extends Collection.extend({
  url: '/api/carparts',
  model: CarPart
}) {
  parse(response) {
    this.pages = response.pages;
    return response.items;
  }
}

export default CarParts;
