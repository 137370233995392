import BaseValidator from './BaseValidator';

export default class Digits extends BaseValidator {
  constructor() {
    super({notDigits: 'The input must contain only digits'});
    this.numericRegex = /^\d+$/;
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    const valid = this.numericRegex.test(value);
    if (!valid) {
      this.addMessage(this.validationMessages.notDigits);
    }
    return valid;
  }
}