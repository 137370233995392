import BaseValidator from './BaseValidator';

export default class GreaterThan extends BaseValidator {
  constructor() {
    super({
      notGreater: "The input is not greater than '%min%'",
      notGreaterInclusive: "The input is not greater than or equal to '%min%'"
    });
  }
  validate(value, values, onSubmit) {
    this.clearMessages();
    let valid = true;
    let message;
    if (this.inclusive) {
      valid = this.min <= value;
      message = this.validationMessages.notGreaterInclusive;
    } else {
      valid = this.min < value;
      message = this.validationMessages.notGreater;
    }
    if (!valid) {
      this.addMessage(message.replace('%min%', this.min));
    }
    return valid;
  }
  setOptions(options) {
    super.setOptions(options);
    this.inclusive = options.inclusive !== undefined ? options.inclusive : false;
    if (options.min === undefined) {
      throw 'No "min" value provided to compare to'
    }
    this.min = options.min;
  }
}