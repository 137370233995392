import {View} from 'backbone.marionette';
import CheckoutFormValidator from './CheckoutFormValidator';
import Order from '../models/Order';
import Totals from './Totals'
import CostOptions from './CostOptions';
import AddressCheck from '../../../behaviors/AddressCheck';
import emptyTemplate from '../templates/EmptyShippingCostOption.hbs'
import HelpBlock from '../../../behaviors/HelpBlock';
import CheckboxCollapse from '../../../behaviors/CheckboxCollapse';

class Checkout extends View.extend({
  template: _.noop,
  behaviors: [AddressCheck, CheckboxCollapse],
  regions: {
    checkoutForm: '#checkoutForm',
    orderTotals: '#order-totals',
    shippingOptions: '.shipping-options',
    paymentOptions: '.payment-options',
    submit: '#form-group-formSubmit'
  },
  ui: {
    email: '#customerEmail',
    login: '#login',
    password: '#userPassword',
    loginButton: '.login-button',
    registerCustomer: '#registerCustomer',
    customerRegisterCustomer: '#customerRegisterCustomer',
    customerSendToInvoiceAddress: '#customerSendToInvoiceAddress',
    customerPassword: '#customerPassword',
    deliveryAddress: '.delivery-address',
    addressCountry: '#addressCountry',
    shippingOptions: '#card-group-toggle-orderShippingMethod input:radio',
    paymentOptions: '#card-group-toggle-orderPaymentMethod input:radio',
    vatCode: '#vatCode',
    vatReverseCharge: '#customerVatReverseCharge'
  },
  events: {
    'change @ui.email': 'emailChange',
    'change @ui.customerRegisterCustomer': 'customerRegisterCustomerChange',
    'change @ui.shippingOptions': 'shippingOptionsChange',
    'change @ui.paymentOptions': 'paymentOptionsChange',
    'click @ui.loginButton': 'handleLogin',
    'change @ui.addressCountry': 'addressCountryChange'
  },
  childViewEventPrefix: 'childview'
}) {
  initialize(options) {
    this.csrf = $('body').data('ajax-csrf');
    this.order = new Order();
    this.order.fetch({
      data: {csrf: this.csrf},
      type: 'POST',
      success: function() {
        this.showChildView('orderTotals', new Totals({model: this.order.totals, el: '#order-totals'}));
        this.showChildView('shippingOptions', new CostOptions(
         {collection: this.order.shippingOptions, el: '.shipping-options', emptyViewOptions: {template: emptyTemplate, className: 'text-center'}}
         ));
        this.showChildView('paymentOptions', new CostOptions({collection: this.order.paymentOptions, el: '.payment-options'}));
      }.bind(this)
    });
    this.showChildView('checkoutForm', new CheckoutFormValidator({el: $('#checkoutForm')}));
    this.showChildView('submit', new View({el: '#form-group-formSubmit', behaviors: [HelpBlock], regions: {helpBlockAttachPoint: '.help-block-attach-point'}}));
    if (this.getUI('email').length !== 0 && this.getUI('email').val() !== '') {
      this.emailChange();
    }
  }
  emailChange() {
    if (!this.getChildView('checkoutForm').isValidFormElement('email')) {
      return;
    }
    const email = this.getUI('email').val();
    $.post({
      data: JSON.stringify({ 'email': email, 'csrf': this.csrf }),
      url: '/api/user/exists',
      dataType: 'json',
      contentType: 'application/json',
      success: function(data) {
        this.handleExistsSuccess(data);
      }.bind(this)
    });
  }
  handleExistsSuccess(data) {
    const loginUI = this.getUI('login');
    const registerCustomerUI = this.getUI('registerCustomer');
    this.getUI('customerRegisterCustomer').prop('checked', false);
    this.getUI('customerPassword').removeClass('show');
    this.getUI('customerPassword').find('input').val('');
    loginUI.toggleClass('d-none', !data.exists);
    registerCustomerUI.toggleClass('d-none', data.exists);
  }
  handleLogin() {
    const email = this.getUI('email').val();
    const password = this.getUI('password').val();
    $.post({
      data: JSON.stringify({ 'email': email, 'password': password, 'csrf': this.csrf }),
      url: '/api/user/login',
      dataType: 'json',
      contentType: 'application/json',
      success: function(data) {
        this.handleLoginSuccess(data);
      }.bind(this)
    });
  }
  handleLoginSuccess(data) {
    if (data.login) {
      location.reload();
    } else {
      this.getChildView('checkoutForm').invalidPassword();
    }
  }
  customerRegisterCustomerChange() {
    this.getChildView('checkoutForm').clearPasswordMessages();
  }
  shippingOptionsChange() {
    const shippingOption = this.$el.find('#card-group-toggle-orderShippingMethod input:radio:checked').val();
    this.$el.find('#form-group-customerSendToInvoiceAddress').toggleClass('d-none', shippingOption !== 'verzending');
    this.getUI('deliveryAddress').toggleClass('show', !this.getUI('customerSendToInvoiceAddress').prop('checked') && shippingOption === 'verzending');
  }
  paymentOptionsChange() {
    const paymentOption = this.$el.find('#card-group-toggle-orderPaymentMethod input:radio:checked').val();
    this.$el.find('#form-group-orderSisowIssuer').toggleClass('d-none', paymentOption !== 'sisow_ideal');
  }

  addressCountryChange() {
    const vatCode = this.getUI('vatCode');
    const country = this.getUI('addressCountry').val();
    const vatCountries = vatCode.data('vat-code-countries');
    const isVatCountry = vatCountries.includes(country);
    if (! isVatCountry) {
      this.getUI('vatReverseCharge').prop('checked', false);
    }
    vatCode.collapse(isVatCountry ? 'show' : 'hide');
  }
  onChildviewChangeFormelement(view) {
    let data = {'csrf': this.csrf}
    data[view.getName()] = view.getValue();
    const formData = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    $.post({
      data: formData,
      url: '/api/order/update',
      dataType: 'json',
      success: function(data) {
        if (data.success) {
          this.order.fetch({
            data: {csrf: this.csrf},
            type: 'POST'
          });
        }
      }.bind(this)
    });
  }
  onChildviewDomRefresh(childView) {
    if (childView instanceof CostOptions) {
      this.getChildView('checkoutForm').reBind(childView.getType() + 'Method', childView.errorState);
    }
  }
  onChildviewSubmissionError(event) {
    this.getChildView('submit').triggerMethod('showValidatorMessage', 'er_ging_iets_verkeerd_bij_het_verwerken_van_dit_formulier');
  }
  onChildviewFocusFormelement(view) {
    this.getChildView('submit').triggerMethod('clearValidatorMessages');
  }
}

export default Checkout;
