import {Behavior} from 'backbone.marionette';
import i18next from 'i18next';

class Stock extends Behavior.extend({}) {
  initialize() {
    const collection = this.view.getOption('collection');
    if (collection) {
      this.listenTo(collection, 'update', this.setStock);
    }
  }
  onRender() {
    this.setStock();
  }
  setStock() {
    const carParts = [];
    this.$el.find('.part-stock').each(function(index, element) {
      carParts.push($(element).data('car-part-id'));
    });
    const csrf = $('body').data('ajax-csrf');
    $.post({
      data: JSON.stringify({ 'carPartIds': carParts, 'csrf': csrf }),
      url: '/api/stock',
      dataType: 'json',
      contentType: 'application/json',
      success: function(data) {
        this.handleSuccess(data);
      }.bind(this)
    });
  }
  handleSuccess(result) {
    if (result.stock) {
      this.$el.find('.part-stock').each(function(index, element) {
        element = $(element);
        const carPartId = element.data('car-part-id');
        if (result.stock[carPartId]) {
          this.updateElement(element, result.stock[carPartId]);
        }
      }.bind(this));
    }
  }
  updateElement(element, stock) {
    const stockInfo = this.getStockInfo(stock);
    element.html('<i class="fa fa-lg ' + stockInfo.iconClass + '"></i>&nbsp;&nbsp;' + i18next.t(stockInfo.text));
  }
  getStockInfo(stock) {
    switch (stock.status) {
      case 4:
        return {
          iconClass: 'fa-exclamation-circle text-danger',
          text: 'niet_op_voorraad'
        };
      case 0:
        if (!stock.stockholding) {
          return {
            iconClass: 'fa-check-circle text-success',
            text: 'binnen_aantal_werkdagen_leverbaar'
          };
        } else {
          return {
            iconClass: 'fa-exclamation-circle text-danger',
            text: 'niet_op_voorraad'
          };
        }
      case 1:
      case 2:
        return {
          iconClass: 'fa-check-circle text-success',
          text: 'beperkte_op_voorraad'
        };
      default:
        return {
          iconClass: 'fa-check-circle text-success',
          text: 'voldoende_op_voorraad'
        };
    }
  }
}

export default Stock;
