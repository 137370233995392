import BaseElement from './BaseElement';

export default class TextareaElement extends BaseElement.extend({
  ui: {
    element: 'textarea'
  }
}) {
  getValue() {
    return this.getUI('element').val();
  }
  setValue(value) {
    this.getUI('element').val(value);
  }
}