import NodeModule from '../../services/NodeModule';
import ImageCarousel from './views/ImageCarousel';

class ImageCarouselNode extends NodeModule {
  start(options) {
    console.log('Starting Image Carousel');
    const imageCarousel = new ImageCarousel({el: options.el});
  }
}

export default ImageCarouselNode;