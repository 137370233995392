import {View} from 'backbone.marionette';
import template from '../templates/option.hbs';

class Option extends View.extend({
  tagName: 'option',
  template: template
}) {
  attributes() {
    let attributes = {value: this.model.id};
    if (this.model.has('selected')) {
      attributes.selected = 'selected';
    }
    if (this.model.has('disabled')) {
      attributes.disabled = 'disabled';
    }
    return attributes;
  }
}

export default Option;