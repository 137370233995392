import { extend } from 'backbone.marionette';

class BaseValidator {
  constructor(validationMessages) {
    this.validationMessages = validationMessages;
    this.messages = [];
  }
  getMessages() {
    return this.messages;
  }
  addMessage(message) {
    this.messages.push(message);
  }
  clearMessages() {
    this.messages = [];
  }
  validate(value, values, onSubmit) {
    throw 'Not implemented';
  }
  setOptions(options) {
    this.breakChainOnFailure = (options.breakChainOnFailure !== undefined) ? options.breakChainOnFailure : false;
    if (options.validationMessages !== undefined) {
      _.extend(this.validationMessages, options.validationMessages);
    }
  }
}

export default BaseValidator;