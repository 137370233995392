import {View} from 'backbone.marionette';
import {Radio} from 'backbone';
import ImageModal from './ImageModal';
import BasketUpdateModal from '../../basket/views/BasketUpdateModal';

class Modal extends View.extend({
  template: _.noop,
  childViewEvents: {
    'destroy:modal': 'destroyModal'
  },
  regions: {
    modal: '#modal-hook'
  }
}) {
  initialize(options) {
    this.channel = Radio.channel('modal');
    this.channel.reply('showModal', this.onShowModal, this);
    this.channel.reply('showImageModal', this.onShowImageModal, this);
    this.channel.reply('showBasketUpdateModal', this.onShowBasketUpdateModal, this);
  }
  onShowModal(view) {
    this.showChildView('modal', view);
  }
  onShowImageModal(image) {
    this.showChildView('modal', new ImageModal(image));
  }
  onShowBasketUpdateModal(count, basketUrl) {
    this.showChildView('modal', new BasketUpdateModal({count: count, basketUrl: basketUrl}));
  }
  destroyModal() {
    this.getRegion('modal').empty();
  }
}

export default Modal;
