import {View} from 'backbone.marionette';
import AddressCheck from '../../../behaviors/AddressCheck';
import HelpBlock from '../../../behaviors/HelpBlock';

export default class Form extends View.extend({
  childViewEventPrefix: 'childview',
  behaviors: [AddressCheck],
  regions: {
    submit: '#form-group-formSubmit',
  }
}) {
  initialize(options) {
    this.addRegion('form', options.formEl);
    this.showChildView('form', new options.type({el: options.formEl}));
    this.showChildView('submit', new View({el: '#form-group-formSubmit', behaviors: [HelpBlock], regions: {helpBlockAttachPoint: '.help-block-attach-point'}}));
  }
  onChildviewSubmissionError(event) {
    this.getChildView('submit').triggerMethod('showValidatorMessage', 'er_ging_iets_verkeerd_bij_het_verwerken_van_dit_formulier');
  }
  onChildviewFocusFormelement(view) {
    this.getChildView('submit').triggerMethod('clearValidatorMessages');
  }
}