import NodeModule from '../../services/NodeModule';
import Checkout from './views/Checkout';

class CheckoutNode extends NodeModule {
  start(options) {
    console.log('Starting Checkout');
    const checkout = new Checkout({el: options.el});
  }
}

export default CheckoutNode;