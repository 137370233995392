import NodeModule from '../../services/NodeModule';
import Modal from './views/Modal';

class ModalNode extends NodeModule {
  start(options) {
    console.log('Starting Modal region');
    const modal = new Modal({el: options.el});
  }
}

export default ModalNode;
